import {SET_APPOINTMENTS} from "constants/ActionTypes";
import {FETCH_START_APPOINTMENTS, FETCH_SUCCESS_APPOINTMENTS, SET_ACTION_APPOINTMENTS, FETCH_ERROR_APPOINTMENTS, SET_APPOINTMENTS_BUSINESS} from "../../constants/ActionTypes";

const INIT_STATE = {
	error: "",
	loading: false,
	message: "",
	response: null,
	action: "",
	appointments: null,
	appointments_business: null,
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
	case FETCH_START_APPOINTMENTS: {
		return {...state, error: "", message: "", loading: true, response: null};
	}
	case FETCH_SUCCESS_APPOINTMENTS: {
		return {...state, error: "", message: "", loading: false, response: null};
	}
	case SET_ACTION_APPOINTMENTS: {
		return {...state, action: action.payload};
	}
	case FETCH_ERROR_APPOINTMENTS: {
		return {...state, loading: false, error: action.payload, message: "", response: false};
	}
	case SET_APPOINTMENTS: {
		return {...state, appointments: action.payload};
	}
	case SET_APPOINTMENTS_BUSINESS: {
		return {...state, appointments_business: action.payload};
	}
	default:
		return state;
	}
};
