import {FETCH_START_CHARGES_DOCTOR, SET_CHARGES_DOCTOR, FETCH_SUCCESS_CHARGES_DOCTOR, SET_ACTION_CHARGES_DOCTOR, FETCH_ERROR_CHARGES_DOCTOR} from "../../constants/ActionTypes";

const INIT_STATE = {
	error: "",
	loading: false,
	message: "",
	response: null,
	action: "",
	charges_doctor: null
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
	case FETCH_START_CHARGES_DOCTOR: {
		return {...state, error: "", message: "", loading: true, response: null};
	}
	case FETCH_SUCCESS_CHARGES_DOCTOR: {
		return {...state, error: "", message: "", loading: false, response: null};
	}
	case SET_ACTION_CHARGES_DOCTOR: {
		return {...state, action: action.payload};
	}
	case FETCH_ERROR_CHARGES_DOCTOR: {
		return {...state, loading: false, error: action.payload, message: "", response: false};
	}
	case SET_CHARGES_DOCTOR: {
		return {...state, charges_doctor: action.payload};
	}
	default:
		return state;
	}
};
