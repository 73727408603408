import {SET_SCHEDULE_DEFAULT, SET_SCHEDULES} from "constants/ActionTypes";
import {FETCH_START_SCHEDULE, FETCH_SUCCESS_SCHEDULE, SET_ACTION_SCHEDULE, FETCH_ERROR_SCHEDULE, RESET_ACTION_SCHEDULES, SET_SCHEDULE_INIT_DEFAULT} from "../../constants/ActionTypes";

const INIT_STATE = {
	error_schedule: "",
	loading_schedule: false,
	message_schedule: "",
	response_schedule: null,
	action_schedule: "",
	schedules: null,
	schedule_default: null
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
	case FETCH_START_SCHEDULE: {
		return {...state, error_schedule: "", message_schedule: "", loading_schedule: true, response_schedule: null, action_schedule: ""};
	}
	case FETCH_SUCCESS_SCHEDULE: {
		return {...state, error_schedule: "", message_schedule: "", loading_schedule: false, response_schedule: null, action_schedule: ""};
	}
	case SET_ACTION_SCHEDULE: {
		return {...state, action_schedule: action.payload};
	}
	case FETCH_ERROR_SCHEDULE: {
		return {...state, loading_schedule: false, error_schedule: action.payload, message_schedule: "", response_schedule: false, action_schedule: ""};
	}
	case SET_SCHEDULE_DEFAULT: {
		return {...state, schedule_default: action.payload};
	}
	case SET_SCHEDULES: {
		return {...state, schedules: action.payload};
	}
	case RESET_ACTION_SCHEDULES: {
		return {...state, loading_schedule: false, error_schedule: "", message_schedule: "", response_schedule: false, action_schedule: ""};
	}
	default:
		return state;
	}
};
