import React, { useState, useEffect } from "react";
import {Button, Checkbox, Select, Col, Form, Input, message, Progress, Row, notification } from "antd";
import {Link, useHistory} from "react-router-dom";

import {useDispatch, useSelector} from "react-redux";
import IntlMessages from "util/IntlMessages";
import {validarCedula, checkPassword} from "util/CustomValidators";
import { userSignUpDoctor } from "../../appRedux/actions/Users";
import { resetActionCommon } from "../../appRedux/actions/Common";
import { duration } from "moment";
import {getAsesors} from "util/Calls"

const FormItem = Form.Item;
const TabDoctor = ({asesor}) => {

	const [valuePassword, setValuePassword] = useState(0);
	const dispatch = useDispatch();
	const history = useHistory();
	const [form] = Form.useForm();
	const [selectTerms, setSelectTerms] = useState(false);
	const [asesors, setAsesors] = useState([]);

	//Responses redux commos
	const response = useSelector(({common}) => common.response);
	const action = useSelector(({common}) => common.action);
	const error = useSelector(({common}) => common.error);
	const loading = useSelector(({common}) => common.loading);

	//Initialize the fields form
	const initialFormState = {firstname: '', lastname: '', email: '', 
		identification: '', password: '', password_confirm: '' }
	const [valuesForm, setValuesForm] = useState(initialFormState);

	useEffect(() => {
		if(action=='doctor_add'){
			message.success("Usuario creado exitosamente, por favor revisa el correo electrónico para activar la cuenta");
			form.resetFields();
			dispatch(resetActionCommon());
			history.push("/signin");
		}
	}, [action]);

	const onFinishFailed = errorInfo => {
		console.log('Failed:', errorInfo);
	};

	const errorM =(er) => (
		<div>
			{er ? <p>Los siguientes datos ya se encuentran registrados: </p>:null}
			{er ? er.map((error, index) => <p key={index}>{error}</p>): null}
			{er ? <p>Intenta recuperando la cuenta</p>:null}
			{er ? <a onClick={() => history.push("/forgot")}>Aquí</a>:null}
		</div>
	);

	useEffect(() => {
		getAsesores();

		return () => {
			resetActionCommon();
		} 
	}, []);

	const getAsesores = async () =>{
		const data = await getAsesors()
		setAsesors(data);
	}

	useEffect(() => {
		if (error) {
			notification.open({
				message: 'Error',
				type: "error",
				description: errorM(error)
			});
            
		}       
	}, [error]);

	const onSubmit = () => {
		if(selectTerms){
			form
				.validateFields()
				.then((values) => {
					//form.resetFields();
					//onCreate(values);
					dispatch(userSignUpDoctor(values, 'doctor_add'));  
				})
				.catch((info) => {
					//console.log('Validate Failed:', info);
				});
		}else{
			message.error("Por favor selecciona los términos y condiciones");
		}
        
	}

	const changeTerms = () => {
		setSelectTerms(!selectTerms);
	}

	return (
		<Form
			initialValues={{valuesForm}}
			name="basic"
			onFinishFailed={onFinishFailed}
			className="gx-signin-form gx-form-row0"
			form={form}
		>

			<Row>
				<Col xs={24} sm={24} md={24} lg={12} xl={12}>
					<FormItem 
						rules={[
							{
								required: true, 
								message: 'Nombre obligatorio'
							}
						]} 
						name="firstname"
					>
						<Input placeholder="Nombre"/>
					</FormItem>
				</Col>
            
				<Col xs={24} sm={24} md={24} lg={12} xl={12}>
					<FormItem rules={[
						{required: true, message: 'Apellido obligatorio'}
					]} 
					name="lastname">
						<Input placeholder="Apellido"/>
					</FormItem>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<FormItem name="identification"
						rules={[
							{
								required: true,
								message: 'Por favor ingrese el DNI o la cédula',
							},
							({ getFieldValue }) => ({
								validator(_, value) {
									if (validarCedula(value)) {
										return Promise.resolve();
									}
									return Promise.reject('Cédula no válida!');
								},
							}),
						]}
					>
						<Input placeholder="DNI/Cédula"/>
					</FormItem>
				</Col>
            
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<FormItem name="email" rules={[{
						required: true, type: 'email', message: 'Correo inválido',
					}]}>
						<Input placeholder="Correo"/>
					</FormItem>
				</Col>
            
				<Col xs={24} sm={24} md={24} lg={12} xl={12}>
					<Form.Item
						name="password"
						rules={[
							{
								required: true,
								message: 'Por favor ingrese la contraseña',
							},
							({ getFieldValue }) => ({
								validator(_, value) {
									var state = checkPassword(value);
									setValuePassword(state);
									if (state > 50) {
										return Promise.resolve();
									}
									return Promise.reject('Contraseña muy débil');
								},
							}),
						]}
						hasFeedback
					>
						<Input.Password placeholder="Contraseña" />
					</Form.Item>
					{ valuePassword != 0 ? <Progress strokeColor={{
						'0%': '#f5222d',
						'100%': '#002766',
					}} percent={valuePassword} style={{top: 0}} /> 
						: null}
				</Col>

				<Col xs={24} sm={24} md={24} lg={12} xl={12}>
					<Form.Item
						name="password_confirm"
						dependencies={['password']}
						hasFeedback
						rules={[
							{
								required: true,
								message: 'Por favor confirma la contraseña!',
							},
							({ getFieldValue }) => ({
								validator(_, value) {
									if (!value || getFieldValue('password') === value) {
										return Promise.resolve();
									}
									return Promise.reject('Las contraseñas ingresadas no coinciden');
								},
							}),
						]}
					>
						<Input.Password placeholder="Confirmar contraseña" />
					</Form.Item>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Form.Item
						label=""
						name="asesor"
						help={asesor == null ? "Si Usted fue referido por algún asesor, lo puede seleccionar en esta casilla" : "Usted fue referido por"}
						initialValue={asesor}
					>
						<Select
							//onSelect={(value) => setEmployeModal(value)}
							style={{ width: '100%' }}
							optionFilterProp="children"
							placeholder="Selecciona el asesor"
							showSearch
							allowClear
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							disabled={asesor == null ? false : true}
							//onSelect={(value) => setEmployeModal(value)}
							//value={employeModal}
                
						>
							{asesors.map(item => (
								<Select.Option key={item.key} value={item.key}>
									{item.label}
								</Select.Option>
							)) }
						</Select>
              
					</Form.Item>
				</Col>
            
            
				<Col span={24}>
					<Form.Item>
						<Checkbox checked={selectTerms} onChange={changeTerms}><IntlMessages id="appModule.iAccept"/></Checkbox>
						<a target="__blank" href="https://landing.novacita.com/terminos-y-condiciones"><span className="gx-signup-form-forgot gx-link"><IntlMessages
							id="appModule.termAndCondition"/></span></a> y las <a target="__blank" href="https://landing.novacita.com/politicas"><span className="gx-signup-form-forgot gx-link">Políticas de Privacidad</span></a>
					</Form.Item>
				</Col>
            
				<Col span={24}>
					<FormItem>
						<Button type="primary" className="gx-mb-0" loading={loading} onClick={onSubmit}>
							<IntlMessages id="app.userAuth.signUp"/>
						</Button>
						<span><IntlMessages id="app.userAuth.or"/></span> <Link to="/signin"><IntlMessages
							id="app.userAuth.signIn"/></Link>
					</FormItem>
				</Col>
			</Row>
		</Form>
	);
}

export default TabDoctor;