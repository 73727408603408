import React from 'react';
import { useSelector } from 'react-redux';
import {Route, Switch, useLocation, Redirect, useHistory} from "react-router-dom";
import asyncComponent from "util/asyncComponent";



const App = ({match, permissions}) => {
  const variables_global = useSelector(({variables}) => variables.variables_global)
  const location = useLocation();
  const history = useHistory();
  
  const user = useSelector(({auth}) => auth.authUser);
  //const check = true;
  if(permissions){
    var check = true;
    if(location.pathname.slice(0, location.pathname.lastIndexOf('/')) == '/horario/employe'){
      //check = permissions.includes('/horario/employe');
    }else if(location.pathname.slice(0, location.pathname.lastIndexOf('/')) == '/vac/horario/employe'){

    }else if(location.pathname.includes('prescripcion/nueva') ){

    }else{
      check = permissions.includes(location.pathname);
    }
    
    
    
    if(user.af){
      if(!user.rl.includes(`${process.env.REACT_APP_ROL_NEGOCIO}`) && !user.rl.includes(`${process.env.REACT_APP_ROL_DOCTOR}`)){
        if(location.pathname == '/profile'){

        }else{
          history.push('/profile');
        }
        
      }else{
      if(location.pathname == '/negocio'){

      }else{
      history.replace('/negocio');
      }
      }
    }
   }
  /* if(permissions){
   console.log(location);
   console.log(permissions);
   var check = permissions.includes(location.pathname);
   console.log(check);
    
  } */
 


  return(
  <div className="gx-main-content-wrapper">
    {
      
      (check === false) &&
      <Redirect to="/dashboard" />
      
    }
    <Switch>
      <Route path={`${match.url}sample`} component={asyncComponent(() => import('./SamplePage'))}/>
      <Route path={`${match.url}dashboard`} component={asyncComponent(() => import('./Admin/Dashboard'))}/>
      <Route path={`${match.url}notAuthorized`} component={asyncComponent(() => import('./ErrorPages/401'))}/>
      <Route path={`${match.url}admin/ajustes/roles`} component={asyncComponent(() => import('./Admin/Settings/Roles'))}/>
      <Route exact path={`${match.url}admin/ajustes/usuarios`} component={asyncComponent(() => import('./Admin/Settings/Users'))}/>
      <Route exact path={`${match.url}admin/ajustes/usuarios/pending`} component={asyncComponent(() => import('./Admin/Settings/Users/pending'))}/>
      <Route exact path={`${match.url}admin/ajustes/usuarios/pending/doctor`} component={asyncComponent(() => import('./Admin/Settings/Users/pending/doctor'))}/>
      <Route exact path={`${match.url}admin/ajustes/usuarios/pending/business`} component={asyncComponent(() => import('./Admin/Settings/Users/pending/business'))}/>
      <Route path={`${match.url}usuarios/user-add`} component={asyncComponent(() => import('./Admin/Settings/Users/actions/AddUser'))}/>
      <Route path={`${match.url}admin/general/genders`} component={asyncComponent(() => import('./Admin/Settings/General/Genders'))}/>
      <Route path={`${match.url}profile`} component={asyncComponent(() => import('./Admin/Profile'))}/>
      {/** BUSINESS */}
      <Route exact path={`${match.url}admin/negocios/clinicas`} component={asyncComponent(() => import('./Admin/Business/Business'))}/>
      <Route exact path={`${match.url}negocio`} component={asyncComponent(() => import('./Doctor/Business'))}/>
      <Route exact path={`${match.url}negocio/colaboradores`} component={asyncComponent(() => import('./Business/Employes'))}/>
      {/** ESPECIALTIES */}
      <Route exact path={`${match.url}admin/negocios/especialidades`} component={asyncComponent(() => import('./Admin/Business/Especialties'))}/>
      <Route exact path={`${match.url}admin/collaborators`} component={asyncComponent(() => import('./Admin/Business/Collaborators'))}/>
      {/** CURRICULUM */}
      <Route exact path={`${match.url}curriculum`} component={asyncComponent(() => import('./Doctor/Curriculum'))}/>
      {/** HORARIO */}
      <Route exact path={`${match.url}horario/:docid?`} component={asyncComponent(() => import('./Doctor/Schedule'))}/>
      <Route exact path={`${match.url}business/horario`} component={asyncComponent(() => import('./Business/Schedules'))}/>
      <Route exact path={`${match.url}business/vac/horario`} component={asyncComponent(() => import('./Business/NoSchedules'))}/>
      <Route exact path={`${match.url}horario/employe/:docid`} component={asyncComponent(() => import('./Doctor/Schedule'))}/>
      <Route exact path={`${match.url}vac/horario/employe/:docid`} component={asyncComponent(() => import('./Doctor/NoSchedule'))}/>
      <Route exact path={`${match.url}vac/horario`} component={asyncComponent(() => import('./Doctor/NoSchedule'))}/>
      <Route exact path={`${match.url}admin/reservas`} component={asyncComponent(() => import('./Doctor/Appointments'))}/>
      <Route exact path={`${match.url}doctor/cobros`} component={asyncComponent(() => import('./Doctor/Charges'))}/>
      <Route exact path={`${match.url}business/ver/reservas`} component={asyncComponent(() => import('./Business/Appointments'))}/>
      <Route exact path={`${match.url}prescripcion`} component={asyncComponent(() => import('./Doctor/Prescription'))}/>
      <Route exact path={`${match.url}prescripcion/nueva/:pacienteId?/:prescripcionId?/:businessId?`} component={asyncComponent(() => import('./Doctor/Prescription/actions/AddPrescription'))}/>
      <Route exact path={`${match.url}historia`} component={asyncComponent(() => import('./Doctor/Histories'))}/>
      {/** PLANES */}
      <Route exact path={`${match.url}admin/ajustes/planes`} component={asyncComponent(() => import('./Admin/Settings/Planes'))}/>
      {/** VARIABLES */}
      <Route exact path={`${match.url}admin/ajustes/variables`} component={asyncComponent(() => import('./Admin/Settings/Variables'))}/>
      <Route exact path={`${match.url}admin/ver/reservas`} component={asyncComponent(() => import('./Admin/Appointments/Schedules'))}/>
      <Route exact path={`${match.url}cobros`} component={asyncComponent(() => import('./Admin/Appointments/Payments'))}/>
      <Route exact path={`${match.url}comisionistas`} component={asyncComponent(() => import('./Admin/Refers/Users'))}/>
      <Route exact path={`${match.url}comisiones`} component={asyncComponent(() => import('./Admin/Refers/Comisiones'))}/>
      <Route exact path={`${match.url}earnings`} component={asyncComponent(() => import('./Admin/Refers/Earnings'))}/>
      <Route exact path={`${match.url}report/earnings`} component={asyncComponent(() => import('./Admin/Refers/ReportEarning'))}/>
      <Route exact path={`${match.url}pagos`} component={asyncComponent(() => import('./Admin/Charges'))}/>  
      <Route exact path={`${match.url}asistentes`} component={asyncComponent(() => import('./Doctor/Users'))}/>
      <Route exact path={`${match.url}admins`} component={asyncComponent(() => import('./Business/Users'))}/>
      <Route exact path={`${match.url}medicamentos`} component={asyncComponent(() => import('./Admin/Medicines'))}/>
      <Route path={`${match.url}invoices`} component={asyncComponent(() => import('./Admin/Invoices'))}/>
      <Route path={`${match.url}reports`} component={asyncComponent(() => import('./Admin/Reports'))}/>
    {
    (check === true) &&
    <Route component={asyncComponent(() => import('./ErrorPages/404'))}/>
    }
    </Switch>
  </div>
  );
};

export default App;
