import React, {useEffect, useState} from "react";
import { Steps, Cascader, Button, message, Card, Row, Col, Input, Form, Skeleton, Select, Divider, DatePicker, Result, Upload, Modal, notification  } from "antd";
import { useParams, useHistory } from "react-router-dom";
import { getActivationBusiness } from "appRedux/actions/Auth";
import {useDispatch, useSelector} from "react-redux";
import { resetActionCommon } from "../appRedux/actions/Common";
import { UserOutlined, SolutionOutlined, FileProtectOutlined, SaveOutlined, MinusCircleOutlined, ScheduleOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { getCitiesSelect } from "appRedux/actions/Cities";
import {getGendersSelect} from "appRedux/actions/Genders";
import {initBusiness} from "appRedux/actions/Business";
import {getTrainingLevelsSelect} from "appRedux/actions/TrainingLevels";
import {getBusinessTypesSelect} from "appRedux/actions/BusinessTypes";
import {getSpecialtiesSelect} from "appRedux/actions/Specialties";
import {Scrollbars} from "react-custom-scrollbars";
import {beforeUploadOnlyImage, beforeUploadImage, getBase64, beforeCurrentDate} from "util/Generic";

const { Step } = Steps;

const VerifyBusiness = () => {
	const { token } = useParams();
	const history = useHistory();
	const dispatch = useDispatch();
	const [current, setCurrent] = React.useState(0);
	const [fileList, setFileList] = React.useState();
	const [fileListProfile, setFileListProfile] = React.useState([]);
	const [previewImage, setPreviewImage] = React.useState();
	const [previewVisible, setPreviewVisible] = React.useState(false);
	const [form] = Form.useForm();
	//Declare all variable for use
	const cities = useSelector(({cities}) => cities.cities);
	const specialties = useSelector(({specialties}) => specialties.specialties);
	const business_types = useSelector(({businessTypes}) => businessTypes.business_types);
	const representante = localStorage.getItem("temp");

	//Initialize the fields form
	const initialFormState1 = { id: token, city: null, address: "", phone: "", telephone: "", business_type: "", email1: ""};
  
	//const initialFormStateG = {  };

	const educationsArray = [
		{}
	];

	const certificationsArray = [
		{}
	];

	const experiencesArray = [
		{}
	];

	//Responses
	const action = useSelector(({business}) => business.action);
	const error = useSelector(({business}) => business.error);
	const loading = useSelector(({common}) => common.loading);
	const [type, setType] = useState(false);
	const [errorActi, setErrorActi] = useState(false);
	const [loadingS, setLoadingS] = useState(false);

	useEffect(() => {
    
		if(action=="business_activation"){
			setType(true);
			message.success("Cuenta activada exitosamente");
			setLoadingS(false);
		}
		dispatch(resetActionCommon());
	}, [action]);

	 
   
	//FORMS
	const [form1, setForm1] = React.useState(initialFormState1);
	const [form2, setForm2] = React.useState(educationsArray);
	const [form3, setForm3] = React.useState(certificationsArray);
	const [form4, setForm4] = React.useState(experiencesArray);
	//const [form5, setForm5] = React.useState(initialFormStateG); 

	const errorM =(er) => (
		<p>Ocurrió un error al procesar la solicitud, por favor intente nuevamente</p>
	);
  
	useEffect(() => {
		if (error && loadingS){
			setLoadingS(false);
			notification.open({
				message: "Error",
				type: "error",
				description: errorM(error)
			});
		}else if(error && !loadingS){
			setErrorActi(true);
			message.info(error);
			setLoadingS(false);
		}       
	}, [error]);

	//Modal
	const next = () => {

		if(current == 0){
			form.validateFields()
				.then(values => {
          
					setForm1(values);
					setCurrent(current + 1);
				})
				.catch(() => {
				}); 
		}else if(current == 1){
			form.validateFields()
				.then(values => {
					setForm3(values);
					setCurrent(current + 1);
				})
				.catch(() => {
				}); 
      
		} 
       

		//setCurrent(current + 1);
    
	};

	const prev = () => {
		setCurrent(current - 1);
	};

	const finishValidation = async() => {
    
		if(fileListProfile.length > 0 ){
			await form.validateFields()
				.then(values => {
					//console.log(values);
					//setForm5(values);
        
  
					let data = {};
					data.F1 = form1;
					data.F2 = form2;
					data.F3 = form3;
					data.F4 = form4;
					data.F5 = values;
  
					setLoadingS(true);
					dispatch(initBusiness(data, "business_activation"));
					//setCurrent(current + 1);
				})
				.catch(() => {
					notification.error("Ocurrió un error al procesar la solicitud, por favor intente nuevamente");
				}); 
		}else{
			message.error("Debe subir al menos imagen de las instalaciones");
		}
    

	};


	//GetCities
	useEffect(() => {
		//Get all cities for select
		dispatch(getActivationBusiness(token, ""));
		dispatch(getCitiesSelect());
		dispatch(getGendersSelect());
		dispatch(getTrainingLevelsSelect());
		dispatch(getBusinessTypesSelect());
		dispatch(getSpecialtiesSelect());
	}, []);
  
	//Formulario para medico: {token}

	const steps = [
		{
			title: "Información Negocio",
			key: 0
		},
		{
			title: "Certificaciones",
			key: 1
		},
		{
			title: "General",
			key: 2
		},
	];

	const FormItem = Form.Item;

	const onFinishFailed = () => {
	};


	function handleChangeS1() {
	}

	const formBasic = (
		<>
			<Form
				//initialValues={{valuesForm}}
				name="basic"
				onFinishFailed={onFinishFailed}
				className="gx-signin-form gx-form-row0"
				form={form}
				labelAlign="top"
				initialValues={form1}
			>

				<Row>
					<Col span={24}>
						<FormItem name="id" hidden>
							<Input placeholder=""/>
						</FormItem>
					</Col>

					<Col span={12}>
						<FormItem name="fullname" label="Representante Legal" labelCol={{ span: 24 }}>
							<Input placeholder={representante} disabled={true}/>
						</FormItem>
					</Col>

					<Col span={12}>
						<FormItem  name="city" label="Ciudad" labelCol={{ span: 24 }}
							rules={[
								{
									required: true,
									message: "Selecciona la ciudad!",
								},
							]}>
							<Cascader options={cities} placeholder="Ciudad" />
						</FormItem>
					</Col>

					<Col span={24}>
						<FormItem  name="address" label="Dirección" labelCol={{ span: 24 }}
							rules={[
								{
									required: true,
									message: "Ingrese la dirección!",
								},
							]}>
							<Input/>
						</FormItem>
					</Col>

					<Col span={8}>
						<FormItem  name="phone" label="Celular" labelCol={{ span: 24 }}
							rules={[
								{
									required: true,
									message: "Ingrese el celular!",
								},
								{
									max: 10,
									message: "Máximo 10 dígitos"
								}
							]}
						>
							<Input type="number"/>
						</FormItem>
					</Col>

					<Col span={8}>
						<FormItem  name="telephone" label="Teléfono recepción" labelCol={{ span: 24 }}
							rules={[
								{
									max: 9,
									message: "Máximo 9 dígitos"
								}
							]}>
							<Input type="number" />
						</FormItem>
					</Col>

					<Col span={8}>
						<FormItem  name="business_type" label="Tipo" labelCol={{ span: 24 }}
							rules={[
								{
									required: true,
									message: "Seleccione",
								},
							]}
						>
							{/* eslint-disable-next-line no-undef */}
							<Select style={{ width: "100%" }} placeholder="Seleccione" options={business_types ? business_types.filter(item => item.value != `${process.env.REACT_APP_ID_CONSULTORIO}` ) : []} onChange={handleChangeS1}>
							</Select>
						</FormItem>
					</Col>

					<Col span={16}>
						<FormItem  name="email1" label="Correo recuperación" labelCol={{ span: 24 }}>
							<Input placeholder="Correo de recuperación" />
						</FormItem>
					</Col>

            
            
          
				</Row>
			</Form>
		</>
	);


	const onFinish = () => {
	};

	const handleChangeImage = info => {
		let {file} = info;
    
		if (info.file.status === "uploading") {
      
			//this.setState({ loading: true });
			return;
		}
		if (info.file.status === "done") {
			// Get this url from response in real world.
			/* getBase64(info.file.originFileObj, imageUrl =>
        this.setState({
          imageUrl,
          loading: false,
        }),
      ); */

			setFileList([file]);
		}

	};
  
  
	const formCertification = (
		<>
			<Form 
				name="certification" 
				onFinish={onFinish} 
				autoComplete="off" 
				className="gx-signin-form gx-form-row0"
				form={form}
			>
				<Form.List name="certifications" key="form3" initialValue={form3}>
					{(fields, { add, remove }) => (
						<>          
							{fields.map(field => (
								<div key={[field.fieldKey, "divcert"]}>

									<Row align="middle">

										<Col span="6">
                
											<Form.Item
												{...field}
												label="Nombre del certificado"
												labelCol={{ span: 24 }}
												name={[field.name, "name_event"]}
												fieldKey={[field.fieldKey, "name_event"]}
												initialValue=''
												rules={[{ required: true, message: "Ingrese el nombre" }]}
											>
												<Input placeholder="Certificado de salud 27000, etc" />
											</Form.Item>
										</Col>

										<Col span="6">
											<Form.Item
												{...field}
												label="Fecha certificación"
												labelCol={{ span: 24 }}
												name={[field.name, "date_ini_cert"]}
												fieldKey={[field.fieldKey, "date_ini_cert"]}
												rules={[{ required: true, message: "Seleccione la fecha de certificación" }]}
											>
												<DatePicker disabledDate={(current) => beforeCurrentDate(current)} showToday={false} style={{width: "100%"}}  placeholder="Seleccione" />
											</Form.Item>
										</Col>

										<Col span="6">
											<Form.Item
												{...field}
												label="Entidad"
												labelCol={{ span: 24 }}
												name={[field.name, "entidad"]}
												fieldKey={[field.fieldKey, "entidad"]}
												initialValue=''
												rules={[{ required: true, message: "Ingrese la entidad que certifica" }]}
											>
												<Input placeholder="Ej. Ministerio de Salud Pública" />
											</Form.Item>
										</Col>

										<Col span="5" align="center">
											<Form.Item
												{...field}
												label="Adjuntar certificado"
												labelCol={{ span: 24 }}
												name={[field.name, "url_certified"]}
												fieldKey={[field.fieldKey, "url_certified"]}
												initialValue={null}
												tooltip="Formatos permitidos: png, jpg, pdf. Máximo 2mb"
											>
												<Upload 
													maxCount={1}
													name="imas"
													//fileList={fileList}
													className="avatar-uploader"
													// eslint-disable-next-line no-undef
													action={`${process.env.REACT_APP_API}upload`}
													beforeUpload={beforeUploadImage}
													onChange={handleChangeImage}
												>
													<Button icon={<UploadOutlined  />} >Subir</Button>
												</Upload>
											</Form.Item>
										</Col>

										<Col span="1" align="center">
											<MinusCircleOutlined style={{color: "red"}} onClick={() => remove(field.name)} />
										</Col>

									</Row>

              
									<Divider />
              
								</div>
							))}
							<Col span={8} offset={16}>
								<Form.Item>
									<Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                Añadir Certificado
									</Button>
								</Form.Item>
							</Col>
            
						</>
					)}
				</Form.List>
			</Form>
    
		</>
	);

  
     
	const handleChangeImagePerfil = info => {
		let {fileList} = info;
    
		if (info.file.status === "uploading") {
     
			//this.setState({ loading: true });
			return;
		}
		if (info.file.status === "done") {
			// Get this url from response in real world.
			/* getBase64(info.file.originFileObj, imageUrl =>
        this.setState({
          imageUrl,
          loading: false,
        }),
      ); */

			setFileListProfile(fileList);
		}

	};

	const handleRemove = (file) => {
		//setFileListProfile([]);
		setFileListProfile(fileListProfile.filter(item => item.uid !== file.uid));
	};

	const handlePreview = async file => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj);
		}
		setPreviewVisible(true);
		setPreviewImage(file.url || file.preview);

	};

	function handleChange() {
	}

	function handleCancel(){
		setPreviewVisible(false);
		setPreviewImage();
	}

	const formGeneral = (
		<>
			<Form
				//initialValues={{valuesForm}}
				name="basic1"
				onFinishFailed={onFinishFailed}
				className="gx-signin-form gx-form-row0"
				form={form}
				labelAlign="top"

			>

				<Row>
					<Col span={24} >
						{
							<FormItem name="specialties" label="Áreas de salud"
								labelCol={{ span: 24 }}
								initialValue={[]}
								rules={[{ required: true, message: "Debe seleccionar al menos una opción" }]}
							>
								<Select
									mode="multiple"
									allowClear
									style={{ width: "100%" }}
									placeholder="Seleccionar áreas de salud"
									onChange={handleChange}
									options={specialties}
									filterOption={(input, option) =>
										option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
								>
								</Select>
							</FormItem>}
					</Col>
            
					<Col span={24} >
						<Form.Item
							label="Imagenes de las instalaciones"
							labelCol={{ span: 24 }}
							name='url_images'
							initialValue={null}
						>
							<Upload 
								listType="picture-card"
								name="imas"
								accept="image/jpeg,image/png"
								fileList={fileListProfile}
								className="avatar-uploader"
								// eslint-disable-next-line no-undef
								action={`${process.env.REACT_APP_API}upload`}
								beforeUpload={beforeUploadOnlyImage}
								onChange={handleChangeImagePerfil}
								onRemove={handleRemove}
								onPreview={handlePreview}
							>
								<UploadOutlined  /> Subir
							</Upload>
						</Form.Item>
					</Col>
          
				</Row>
			</Form>
		</>
	);

	return (
		<Scrollbars autoHide >   
			<Modal
				visible={previewVisible}
				title="Preview"
				footer={null}
				onCancel={handleCancel}
			>
				<img alt="example" style={{ width: "100%" }} src={previewImage} />
			</Modal>
			{loading && !loadingS ? <Skeleton /> : !type ? errorActi ? <Result
				status="error"
				title="Error al activar cuenta!"
				subTitle="El enlace expiró o no es el correcto"
				extra={[
					<Button type="primary" key="console">
						<a onClick={() => history.replace("/signin")}>Ir al inicio</a>
					</Button>,
				]}
        
			/> :
				<Row justify="center" align="middle">
					<Col span={21} offset={3}>
						<Card title="Validación datos clínica/consultorio">
							<Steps current={current}>
								{steps.map(item => (
									<Step key={item.title} title={item.title} icon={ item.key == "0" ? <UserOutlined /> : item.key == "1" ? <SolutionOutlined />: item.key == "2" ? <FileProtectOutlined /> : item.key == "3" ? <ScheduleOutlined /> : <SaveOutlined />} />
								))}
							</Steps>
							<Divider />
							<div className="steps-content">
								{steps[current].key == "0" ? formBasic : steps[current].key == "1" ? formCertification : steps[current].key =="2" ? formGeneral : "Hubo un error!"}
							</div>
							<div className="steps-action">
								{current < steps.length - 1 && (
									<Button type="primary" onClick={() => next()}>
              Siguiente
									</Button>
								)}
								{current === steps.length - 1 && (
									<Button loading={loadingS} type="primary" onClick={() => finishValidation()}>
              Finalizar
									</Button>
								)}
								{current > 0 && (
									<Button disabled={loadingS}  onClick={() => prev()}>
              Atrás
									</Button>
								)}
							</div>
						</Card>
					</Col>
				</Row> : <Result
				status="success"
				title="Cuenta en proceso de verificación!"
				subTitle="En estos momentos verificaremos la informacíon proporcionada, se informará mediante correo electrónico la activación"
				extra={[
					<Button type="primary" key="console">
						<a onClick={() => history.replace("/signin")}>Ir al inicio</a>
					</Button>,
				]}
			/>
			}
		</Scrollbars>
	);
};

export default VerifyBusiness;
