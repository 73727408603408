import {FETCH_ERROR, FETCH_START, FETCH_SUCCESS, HIDE_MESSAGE, SHOW_MESSAGE} from "constants/ActionTypes";
import {TOGGLE_COLLAPSED_NAV, WINDOW_WIDTH, FETCH_OK, UPDATE_PERMISSIONS, FETCH_ACTION, RESET_ACTION_COMMON} from "../../constants/ActionTypes";

const INIT_STATE = {
	error: "",
	loading: false,
	message: "",
	navCollapsed: true,
	width: window.innerWidth,
	pathname: "/",
	response: null,
	action: ""
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
	case "@@router/LOCATION_CHANGE": {
		return {
			...state,
			pathname: action.payload.location.pathname,
			navCollapsed: false
		};
	}
	case WINDOW_WIDTH:
		return {
			...state,
			width: action.width,
		};
	case TOGGLE_COLLAPSED_NAV: {
		return {
			...state,
			navCollapsed: action.navCollapsed
		};
	}
	case FETCH_START: {
		return {...state, error: "", message: "", loading: true, response: null, action: ""};
	}
	case FETCH_SUCCESS: {
		return {...state, error: "", message: "", loading: false, response: null, action: ""};
	}
	case FETCH_OK: {
		return {...state, error: "", message: "", loading: false, response: true, action: "FETCH_OK"};
	}
	case FETCH_ACTION: {
		return {...state, error: "", message: "", loading: false, response: true, action: action.payload};
	}
	case UPDATE_PERMISSIONS: {
		return {...state, error: "", message: "", loading: false, response: true, action: "UPDATE_PERMISSIONS"};
	}
	case SHOW_MESSAGE: {
		return {...state, error: "", message: action.payload, loading: false, response: null, action: ""};
	}
	case FETCH_ERROR: {
		return {...state, loading: false, error: action.payload, message: "", response: false, action: ""};
	}
	case HIDE_MESSAGE: {
		return {...state, loading: false, error: "", message: "", response: null, action: ""};
	}
	case RESET_ACTION_COMMON: {
		return {...state, loading: false, error: "", message: null, response: null, action: ""};
	}
	default:
		return state;
	}
};
