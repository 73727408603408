import React, {useEffect, useState} from "react";
import { Button, message, Card, Row, Col, Skeleton, Result, Alert  } from "antd";
import { useParams, useHistory } from "react-router-dom";
import {verifyInvitation, acceptInvitation, declineInvitation} from "util/Calls";


const VerifyDoctor = () => {
	const { token } = useParams();
	const history = useHistory();
	const [isValid, setIsValid] = useState(null);
	const [info, setInfo] = useState();

	const verifyStatus = async (token) =>{
		const data = await verifyInvitation(token);
		return data;
	};

	const accept = async (token) =>{
		const data = await acceptInvitation(token);
		return data;
	};

	const decline = async (token) =>{
		const data = await declineInvitation(token);
		return data;
	};
  
	useEffect(() => {
		verifyStatus(token).then((response)=> {
			if(response.result){
				setInfo(response.doctor);
				setIsValid(true);
			}else{
				setIsValid(false);
			}
		});
    
    
     
    
	}, []);


	const onConfirm = (token) =>{
		accept(token).then((response) => {
			if(response){
				message.success("Invitación aceptada");
				setTimeout(function(){ history.replace("/login"); }, 1000);
			}else{
				message.error("Ocurrio un error por favor intente nuevamente");
			}
		});    
	};

	const handleC = (token) => {
		decline(token).then((response) => {
			if(response){
				message.error("Invitación rechazada");
				setTimeout(function(){ history.replace("/login"); }, 1000);
        
			}else{
				message.error("Ocurrio un error por favor intente nuevamente");
			}
		}); 
	};

	return (
		<>
			{isValid == null ? <Skeleton /> : 
				isValid ? 
					<Row type="flex" justify="center" align="middle" style={{minHeight: "100vh"}}>
						<Col span={12} offset={12}>
							<Card title="¿Aceptar invitación?">
								<Alert message={<p>La institución {info.name} envió una solicitud para que te unas al equipo de trabajo </p>}>
								</Alert>
								<Button key="back" onClick={() => handleC(token)}>
            Cancelar
								</Button>,
								<Button key="submit" type="primary" onClick={() => onConfirm(token)} >
            Aceptar
								</Button>
							</Card>
						</Col>
					</Row>
					: 
					<Result
						status="error"
						title="Ocurrio un error"
						subTitle="El enlace es inválido o ya se verificó anteriormente, si cree que es un error comuniquese con soporte"
						extra={[
							<Button type="primary" key="console" onClick={() => history.replace("/login")}>
        Ir al inicio
							</Button>
						]}
					/>
			}
		</>
	);
};

export default VerifyDoctor;
