import {INIT_URL } from "constants/ActionTypes";
import {  FETCH_ERROR_REPORT_COLABORADORES,   FETCH_START_REPORT_COLABORADORES,  FETCH_SUCCESS_REPORT_COBROS, FETCH_SUCCESS_REPORT_COLABORADORES, FETCH_SUCCESS_REPORT_PAGOS_LINEA, FETCH_SUCCESS_REPORT_RESERVAS, SET_ACTION_REPORT_COBROS, SET_ACTION_REPORT_COLABORADORES, SET_ACTION_REPORT_PAGOS_LINEA, SET_ACTION_REPORT_RESERVAS, SET_REPORT_COBROS, SET_REPORT_COLABORADORES, SET_REPORT_PAGOS_LINEA, SET_REPORT_RESERVAS  } from "../../../constants/ActionTypes";

const INIT_STATE = {
	initURL: "",
	error_users: "",
	loading_users: false,
	message_users: "",
	action_users: "",
	cobros: null,
	reservas: null,
	colaboradores: null
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {


	case INIT_URL: {
		return {...state, initURL: action.payload};
	}

	case FETCH_START_REPORT_COLABORADORES: {
		return {...state, error_cobros: "", message_cobros: "", loading_cobros: true};
	}

	case FETCH_SUCCESS_REPORT_COLABORADORES: {
		return {...state, error_cobros: "", message_cobros: "", loading_cobros: false};
	}

	case FETCH_ERROR_REPORT_COLABORADORES: {
		return {...state, loading_cobros: false, error_cobros: action.payload, message_cobros: ""};
	}

	case SET_ACTION_REPORT_COLABORADORES: {
		return {
			...state,
			action_cobros: action.payload,
		};
	}

	case SET_REPORT_COLABORADORES: {
		return {
			...state,
			colaboradores: action.payload,
		};
	}

	
	default:
		return state;
	}
};
