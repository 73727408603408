import {INIT_URL, TRAINING_LEVEL_SELECT_SET } from "../../constants/ActionTypes";

const INIT_STATE = {
	initURL: "",
	trainings: null
};

export default (state = INIT_STATE, action) => {
  
	switch (action.type) {

	case INIT_URL: {
		return {...state, initURL: action.payload};
	}

	case TRAINING_LEVEL_SELECT_SET: {
		return {
			...state,
			trainings: action.payload,
		};
	}

	default:
		return state;
	}
};
