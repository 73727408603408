import {SET_PLANS} from "constants/ActionTypes";
import {FETCH_START_PLANS, FETCH_SUCCESS_PLANS, SET_ACTION_PLANS, FETCH_ERROR_PLANS} from "../../constants/ActionTypes";

const INIT_STATE = {
	error_plans: "",
	loading_plans: false,
	message_plans: "",
	response_plans: null,
	action_plans: "",
	plans: null
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
	case FETCH_START_PLANS: {
		return {...state, error_plans: "", message_plans: "", loading_plans: true, response_plans: null, action_plans: ""};
	}
	case FETCH_SUCCESS_PLANS: {
		return {...state, error_plans: "", message_plans: "", loading_plans: false, response_plans: null, action_plans: ""};
	}
	case SET_ACTION_PLANS: {
		return {...state, action_plans: action.payload};
	}
	case FETCH_ERROR_PLANS: {
		return {...state, loading_plans: false, error_plans: action.payload, message_plans: "", response_schedule: false, response_plans: ""};
	}
	case SET_PLANS: {
		return {...state, plans: action.payload};
	}
	default:
		return state;
	}
};
