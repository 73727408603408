import React from "react";
import {useDispatch} from "react-redux";
import {Avatar, Col, Popover, Row} from "antd";
import {userSignOut} from "../../appRedux/actions/Auth";
import { useHistory } from "react-router-dom";

const UserProfile = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const user = JSON.parse(localStorage.getItem("user"));
  
	const userMenuOptions = (
		<ul className="gx-user-popover">
			<li onClick={() => history.push("/profile")}>Mi Perfil </li>
			<li onClick={() => dispatch(userSignOut())}>Cerrar sesión
			</li>
		</ul>
	);

	return (
		<>
			<div className="gx-align-items-center gx-mb-4 gx-avatar-row">
				<Row>
					<Col span="6">
						{/* eslint-disable-next-line no-undef */}
						<Avatar src={user ? user.photo : "https://via.placeholder.com/150x150"}
							className="gx-size-40 gx-pointer gx-mr-3" alt=""/>
					</Col>
					<Col span="18">
						<Popover placement="bottomRight" content={userMenuOptions} trigger="click">        
							<span style={{whiteSpace: "pre-line", textOverflow: "ellipsis"}} className="gx-avatar-name">{user ? user.fullname.toUpperCase(): "Cargando..."}<i
								className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/></span>
						</Popover>
					</Col>
				</Row>    
      
			</div>
		</>
	);
};

export default UserProfile;
