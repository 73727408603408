import React, { useState } from "react";
import {Alert, Button, Form, Input, Radio, Progress, message, Affix} from "antd";
import IntlMessages from "util/IntlMessages";
import { useHistory} from "react-router-dom";
import { getActivationPacient } from 'appRedux/actions/Auth';
import {useDispatch, useSelector} from "react-redux";
import { resetActionCommon } from "../appRedux/actions/Common";
import axios from 'util/Api';
import { checkPassword} from "util/CustomValidators";
import { TeamOutlined } from "@ant-design/icons";

const FormItem = Form.Item;


const ForgotPassword = () => {
	const history = useHistory();
	const [valuePassword, setValuePassword] = useState(0);
	//Responses redux commos
	const [form] = Form.useForm();
	const [messageError, setMessageError] = useState(false);
	const [formCode, setFormCode] = useState(false);
	const [valueMethod, setValueMethod] = useState(null);
	const [methods, setMethods] = useState();
	const [postCode, setPostCode] = useState(false);
	const [messageError2, setMessageError2] = useState(false);
	const [messageError3, setMessageError3] = useState(false);
	const [isformReset, setFormReset] = useState(false);

	const [loadingF1, setLoadingF1] = useState(false);
	const [loadingF2, setLoadingF2] = useState(false);
	const [idUser, setIdUser] = useState();

	const onFinishFailed = errorInfo => {
		//console.log('Failed:', errorInfo);
	};

	const onFinish = values => {
		//console.log("Received values of form",values)
	};

	const back = () => {
		history.push('/signin');
	};

	const onSubmit = () => {
		form
			.validateFields()
			.then((values) => {
				//form.resetFields();
				//onCreate(values);
				//dispatch(userSignUpDoctor(values, 'doctor_add'));  
				setLoadingF1(true);
				axios.post('passwords/recovery', values).then(({data}) => {
            
					if(data.result){
						setFormCode(true);
						setMethods(data.data);
						setIdUser(data.cd);
						setLoadingF1(false);
					}else{
						setMessageError(true);
						setLoadingF1(false);
					}
				}).catch(function (error) {
					//dispatch({type: FETCH_ERROR, payload: error});
					//console.log("Error****:", error);
				});
			})
			.catch((info) => {
				console.log('Validate Failed:', info);
			});
	};

	const onSubmit2 = () => {
		if(valueMethod == null || valueMethod == ''){
			setMessageError2(true);
		}else{
			form
				.validateFields()
				.then(() => {
					//form.resetFields();
					//onCreate(values);
					//dispatch(userSignUpDoctor(values, 'doctor_add'));  
					setLoadingF1(true);
					axios.post('passwords/getcode', {method: valueMethod, id: idUser}).then(({data}) => {
                
						if(data.result){
							setPostCode(true);
							setLoadingF1(false);
						}else{
							setLoadingF1(false);
						}
					}).catch(function (error) {
						//dispatch({type: FETCH_ERROR, payload: error});
						console.log("Error****:", error);
					});
				})
				.catch((info) => {
					console.log('Validate Failed:', info);
				});
		}
        
	};

	const onSubmit3 = () => {
		form
			.validateFields()
			.then((values) => {
				//form.resetFields();
				//onCreate(values);
				//dispatch(userSignUpDoctor(values, 'doctor_add'));  
				setLoadingF1(true);
				axios.post('passwords/confirmcode', values).then(({data}) => {
            
					if(data.result){
						setFormReset(true);
						setLoadingF1(false);
					}else{
						setMessageError3(true);
						setLoadingF1(false);
					}
				}).catch(function (error) {
					//dispatch({type: FETCH_ERROR, payload: error});
					console.log("Error****:", error);
				});
			})
			.catch((info) => {
				console.log('Validate Failed:', info);
			});
	};

	const onSubmitFinish = () => {
		form
			.validateFields()
			.then((values) => {
				//form.resetFields();
				//onCreate(values);
				//dispatch(userSignUpDoctor(values, 'doctor_add'));  
				setLoadingF1(true);
				axios.post('passwords/confirm', {data: values, id: idUser}).then(({data}) => {
                
					if(data.result){
						message.info("Contraseña cambiada con exito, inicie sesión");
						setLoadingF1(false);
						history.push("/signin");
					}else{
						setLoadingF1(false);
						message.error("Error al cambiar la contraseña, intente más tarde");
					}
				}).catch(function (error) {
					//dispatch({type: FETCH_ERROR, payload: error});
					console.log("Error****:", error);
				});
			})
			.catch((info) => {
				console.log('Validate Failed:', info);
			});        
	};

	const getFormInit = (
		<>
			<div className="gx-login-header">
				<img width="50%" src={require("assets/images/logo_footer_azul.png")} alt="novacita" title="novacita"/>
			</div>
			<div className="gx-mb-4">
				<h2>No tienes acceso a tu cuenta?</h2>
				<p>No te preocupes recuperar la contraseña es fácil. Simplemente escribanos el correo electrónico que ha registrado o su número/dni/ruc de cédula</p>
			</div>
			<Form
				initialValues={{ remember: true }}
				name="basic"
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				form={form}
				className="gx-signin-form gx-form-row0">
				<FormItem name="email_identification" rules={[{ required: true, message: 'Por favor ingresa el correo o la cédula!' }]}>
					<Input className='gx-input-lineheight' placeholder="Correo electrónico o cédula"/>
				</FormItem>
				{messageError ? <Alert message="Cuenta no encontrada" type="error" /> : null}

				<FormItem>
					<Button type="primary" onClick={onSubmit} loading={loadingF1}>
              Buscar cuenta
					</Button>
					<Button type="link" onClick={back}>Ir al inicio</Button>
				</FormItem>
			</Form>
		</>
	);

	const radioStyle = {
		display: 'block',
		height: '30px',
		lineHeight: '30px',
	};

	const onChangeMethod = e =>{
		setValueMethod(e.target.value);
	};

	const getFormCode = (
		<>
			<div className="gx-login-header">
				<img width="50%" src={require("assets/images/logo_footer_azul.png")} alt="wieldy" title="wieldy"/>
			</div>
			<div className="gx-mb-4">
				<h2>Selecciona el metodo de verificación</h2>
				<p>Enviaremos un código de seguridad, asegurate de tener acceso al metodo seleccionado</p>
			</div>
			<Form
				initialValues={{ remember: true }}
				name="basic"
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				form={form}
				className="gx-signin-form gx-form-row0">
				<Radio.Group name="method" onChange={onChangeMethod} value={valueMethod}>
					{methods ? methods.map((item) => {
						return <Radio style={radioStyle} disabled={item.type=="phone" ? true: false} key={item.type} value={item.type}>
							{item.value}
						</Radio>;
					}) : <p>No existen metodos disponibles</p>}
				</Radio.Group>
				{messageError2 ? <Alert message="Por favor selecciona un metodo disponible" type="error" /> : null}
				<FormItem>
					<Button type="primary" onClick={onSubmit2} loading={loadingF1}>
              Siguiente
					</Button>
					<Button type="link" onClick={back}>Ir al inicio</Button>
				</FormItem>
          
			</Form>
		</>
	);

	const getPostCode = (
		<>
			<div className="gx-login-header">
				<img width="50%" src={require("assets/images/logo_footer_azul.png")} alt="wieldy" title="wieldy"/>
			</div>
			<div className="gx-mb-4">
				<h2>Verificación de código</h2>
				<p>Ingresa el código recibido</p>
			</div>
			<Form
				initialValues={{ remember: true }}
				name="basic"
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				form={form}
				className="gx-signin-form gx-form-row0">
				<FormItem name="code" rules={[{ required: true, message: 'Por favor ingresa el código!' }]}>
					<Input className='gx-input-lineheight' placeholder="Código recibido"/>
				</FormItem>

				{messageError3 ? <Alert message="Código no válido" type="error" /> : null}

				<FormItem>
					<Button type="primary" onClick={onSubmit3} loading={loadingF1}>
              Siguiente
					</Button>
					<Button type="link" onClick={back}>Ir al inicio</Button>
				</FormItem>
			</Form>
		</>
	);

	const formReset = (
		<>
			<div className="gx-login-header">
				<img width="50%" src={require("assets/images/logo_footer_azul.png")} alt="wieldy" title="wieldy"/>
			</div>
			<div className="gx-mb-4">
				<h2>Cambiar contraseña</h2>
				<p>Por favor ingresa la nueva contraseña</p>
			</div>
			<Form
				initialValues={{ remember: true }}
				name="basic"
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				className="gx-signin-form gx-form-row0">
        
				<Form.Item
					name="password"
					rules={[
						{
							required: true,
							message: 'Por favor ingresa la contraseña',
						},
						() => ({
							validator(_, value) {
								var state = checkPassword(value);
								setValuePassword(state);
								if (state > 50) {
									return Promise.resolve();
								}
								return Promise.reject('Contraseña muy debil');
							},
						}),
					]}
					hasFeedback
				>
					<Input.Password placeholder="Contraseña" />
				</Form.Item>
				{ valuePassword != 0 ? <Progress strokeColor={{
					'0%': '#f5222d',
					'100%': '#002766',
				}} percent={valuePassword} style={{top: 0}} /> 
					: null}

				<Form.Item
					name="password_confirm"
					dependencies={['password']}
					hasFeedback
					rules={[
						{
							required: true,
							message: 'Por favor confirma la contraseña!',
						},
						({ getFieldValue }) => ({
							validator(_, value) {
								if (!value || getFieldValue('password') === value) {
									return Promise.resolve();
								}
								return Promise.reject('Las contraseñas ingresadas no coinciden');
							},
						}),
					]}
				>
					<Input.Password placeholder="Confirmar contraseña" />
				</Form.Item>

				<FormItem>
					<Button type="primary" onClick={onSubmitFinish} loading={loadingF1}>
              Cambiar contraseña
					</Button>
					<Button type="link" onClick={back}>Ir al inicio</Button>
				</FormItem>
			</Form>
		</>
	);

	return (
		<>
			<Affix offsetTop={20} >
				<Button href="http://localhost:5000/" style={{float: 'right'}} type="primary" icon={<TeamOutlined />}>
          Área de Médicos/Clínicas
				</Button>
				<Button href="http://localhost:3000/" style={{float: 'right', marginRight: '20px', backgroundColor: 'cyan'}} icon={<TeamOutlined />}>
          Área de Pacientes
				</Button>
			</Affix>
			<div className="gx-login-container">
				<div className="gx-login-content">

					{isformReset ? formReset : postCode ? getPostCode: formCode == false ? getFormInit :  getFormCode}

				</div>
			</div>
		</>
	);
};

export default ForgotPassword;
