import {INIT_URL } from "constants/ActionTypes";
import { FETCH_ERROR_REPORT_COBROS, FETCH_ERROR_REPORT_PAGOS_LINEA, FETCH_ERROR_REPORT_RESERVAS, FETCH_START_REPORT_COBROS, FETCH_START_REPORT_PAGOS_LINEA, FETCH_START_REPORT_RESERVAS, FETCH_SUCCESS_REPORT_COBROS, FETCH_SUCCESS_REPORT_PAGOS_LINEA, FETCH_SUCCESS_REPORT_RESERVAS, SET_ACTION_REPORT_COBROS, SET_ACTION_REPORT_PAGOS_LINEA, SET_ACTION_REPORT_RESERVAS, SET_REPORT_COBROS, SET_REPORT_PAGOS_LINEA, SET_REPORT_RESERVAS  } from "../../../constants/ActionTypes";

const INIT_STATE = {
	initURL: "",
	error_users: "",
	loading_users: false,
	message_users: "",
	action_users: "",
	cobros: null,
	reservas: null,
	pagos_linea: null
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {


	case INIT_URL: {
		return {...state, initURL: action.payload};
	}

	case FETCH_START_REPORT_COBROS: {
		return {...state, error_cobros: "", message_cobros: "", loading_cobros: true};
	}

	case FETCH_SUCCESS_REPORT_COBROS: {
		return {...state, error_cobros: "", message_cobros: "", loading_cobros: false};
	}

	case FETCH_ERROR_REPORT_COBROS: {
		return {...state, loading_cobros: false, error_cobros: action.payload, message_cobros: ""};
	}

	case SET_ACTION_REPORT_COBROS: {
		return {
			...state,
			action_cobros: action.payload,
		};
	}

	case SET_REPORT_COBROS: {
		return {
			...state,
			cobros: action.payload,
		};
	}

	//
	case FETCH_START_REPORT_RESERVAS: {
		return {...state, error_cobros: "", message_cobros: "", loading_cobros: true};
	}

	case FETCH_SUCCESS_REPORT_RESERVAS: {
		return {...state, error_cobros: "", message_cobros: "", loading_cobros: false};
	}

	case FETCH_ERROR_REPORT_RESERVAS: {
		return {...state, loading_cobros: false, error_cobros: action.payload, message_cobros: ""};
	}

	case SET_ACTION_REPORT_RESERVAS: {
		return {
			...state,
			reservas: action.payload,
		};
	}

	case SET_REPORT_RESERVAS: {
		return {
			...state,
			reservas: action.payload,
		};
	}

	//
	case FETCH_START_REPORT_PAGOS_LINEA: {
		return {...state, error_cobros: "", message_cobros: "", loading_cobros: true};
	}

	case FETCH_SUCCESS_REPORT_PAGOS_LINEA: {
		return {...state, error_cobros: "", message_cobros: "", loading_cobros: false};
	}

	case FETCH_ERROR_REPORT_PAGOS_LINEA: {
		return {...state, loading_cobros: false, error_cobros: action.payload, message_cobros: ""};
	}

	case SET_ACTION_REPORT_PAGOS_LINEA: {
		return {
			...state,
			pagos_linea: action.payload,
		};
	}

	case SET_REPORT_PAGOS_LINEA: {
		return {
			...state,
			pagos_linea: action.payload,
		};
	}

	default:
		return state;
	}
};
