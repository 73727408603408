import {INIT_URL, FETCH_START_DASHBOARD, FETCH_SUCCESS_DASHBOARD, FETCH_ERROR_DASHBOARD, SET_ACTION_DASHBOARD, SET_DASHBOARD} from "constants/ActionTypes";

const INIT_STATE = {
	initURL: "",
	dashboard: null,
	error_dashboard: "",
	loading_dashboard: false,
	message_dashboard: "",
	action_dashboard: ""
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {


	case INIT_URL: {
		return {...state, initURL: action.payload};
	}

	case FETCH_START_DASHBOARD: {
		return {...state, error_dashboard: "", message_dashboard: "", loading_dashboard: true};
	}

	case FETCH_SUCCESS_DASHBOARD: {
		return {...state, error_dashboard: "", message_dashboard: "", loading_dashboard: false};
	}

	case FETCH_ERROR_DASHBOARD: {
		return {...state, loading_dashboard: false, error_dashboard: action.payload, message_dashboard: ""};
	}

	case SET_ACTION_DASHBOARD: {
		return {
			...state,
			action_dashboard: action.payload,
		};
	}

	case SET_DASHBOARD: {
		return {
			...state,
			dashboard: action.payload,
		};
	}

	default:
		return state;
	}
};
