import { FETCH_ERROR_VARIABLES, FETCH_START_VARIABLES, FETCH_SUCCESS_VARIABLES, 
	SET_VARIABLES, SET_VARIABLES_ACTION, SET_VARIABLES_GLOBAL, FETCH_START_VARIABLES_MODAL,
	FETCH_SUCCESS_VARIABLES_MODAL, FETCH_ERROR_VARIABLES_MODAL, SET_VARIABLES_ACTION_MODAL } from "constants/ActionTypes";
import {INIT_URL} from "constants/ActionTypes";
  
const INIT_STATE = {
	initURL: "",
	variables: null,
	error: "",
	loading: false,
	message: "",
	response: null,
	action: [[],[]],
	error_modal: "",
	loading_modal: false,
	message_modal: "",
	response_modal: null,
	action_modal: "",
	variables_global: null,
};
  
export default (state = INIT_STATE, action) => {
    
	switch (action.type) {
  
	case INIT_URL: {
		return {...state, initURL: action.payload};
	}
	case SET_VARIABLES_GLOBAL: {
		return {
			...state,
			variables_global: action.payload,
		};
	}
	case SET_VARIABLES: {
		return {
			...state,
			variables: action.payload,
		};
	}
	case FETCH_START_VARIABLES: {
		return {...state, error: "", message: "", loading: true, response: null, action: ""};
	}
	case FETCH_SUCCESS_VARIABLES: {
		return {...state, error: "", message: "", loading: false, response: null, action: ""};
	}
	case FETCH_ERROR_VARIABLES: {
		return {...state, loading: false, error: action.payload, message: "", response: false, action: ""};
	}
	case SET_VARIABLES_ACTION: {
		return {
			...state,
			action: action.payload,
		};
	}
	case FETCH_START_VARIABLES_MODAL: {
		return {...state, error_modal: "", message_modal: "", loading_modal: true, response_modal: null, action_modal: ""};
	}
	case FETCH_SUCCESS_VARIABLES_MODAL: {
		return {...state, error_modal: "", message_modal: "", loading_modal: false, response_modal: null, action_modal: ""};
	}
	case FETCH_ERROR_VARIABLES_MODAL: {
		return {...state, loading_modal: false, error_modal: action.payload, message_modal: "", response_modal: false, action_modal: ""};
	}
	case SET_VARIABLES_ACTION_MODAL: {
		return {
			...state,
			action_modal: action.payload,
		};
	}
	default:
		return state;
	}
};