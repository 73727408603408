import React, {useEffect, useState} from "react";
import { message, Result, Button, Skeleton } from "antd";
import { useParams, Link } from "react-router-dom";
import { getActivationPacient } from "appRedux/actions/Auth";
import {useDispatch, useSelector} from "react-redux";
import { resetActionCommon } from "../appRedux/actions/Common";

const VerifyPacient = () => {
	const { token } = useParams();
	const dispatch = useDispatch();
	//Responses redux commos
	const response = useSelector(({common}) => common.response);
	const action = useSelector(({common}) => common.action);
	const error = useSelector(({common}) => common.error);
	const loading = useSelector(({common}) => common.loading);
	const [type, setType] = useState(false);

	useEffect(() => {
		if(response && action=="pacient_activation"){
			setType(true);
			message.success("Cuenta activada exitosamente");
		}
		dispatch(resetActionCommon());
	}, [response]);

	useEffect(() => {
		dispatch(getActivationPacient(token, "pacient_activation"));
	}, []);

	useEffect(() => {
		if (error) {
			message.info(error);
		}       
	}, [error]);

	return (
		<div className="gx-page-error-container">
			<div className="gx-page-error-content">
				{loading ? <Skeleton />: type ? <Result
					status="success"
					title="Cuenta activada!"
					subTitle="Por favor inicia sesion"
					extra={[
						<Button type="primary" key="console">
							<Link to="/signin">Ir al inicio</Link>
						</Button>,
					]}
				/>: <Result
					status="error"
					title="Error al activar cuenta!"
					subTitle="El enlace expiró o no es el correcto"
					extra={[
						<Button type="primary" key="console">
							<Link to="/signin">Ir al inicio</Link>
						</Button>,
					]}
        
				/>}
			</div>
		</div>
	);
};

export default VerifyPacient;
