import React, {useEffect, useState} from "react";
import { Button, Card, Row, Col, Skeleton, Result  } from "antd";
import { useParams, useHistory } from "react-router-dom";
import {verifyInvitationAsesor} from "util/Calls";
import TabUserAsesor from "components/Register/TabUserAsesor";


const VerifyAsesor = () => {
	const { token } = useParams();
	const history = useHistory();
	const [isValid, setIsValid] = useState(null);

	const verifyStatus = async (token) =>{
		const data = await verifyInvitationAsesor(token);
		return data;
	};

	useEffect(() => {
		verifyStatus(token).then((response)=> {
			if(response.result){
				//setInfo(response.doctor);
				setIsValid(true);
			}else{
				setIsValid(false);
			}
		});
    
    
     
    
	}, []);




	return (
		<>
			{isValid == null ? <Skeleton /> : 
				isValid ? 
					<Row type="flex" justify="center" align="middle" style={{minHeight: "100vh"}}>
						<Col span={12} offset={12}>
							<Card title="Registro de asesor">
								<TabUserAsesor token={token}/>
							</Card>
						</Col>
					</Row>
					: 
					<Result
						status="error"
						title="Ocurrio un error"
						subTitle="El enlace es inválido o ya se verificó anteriormente, si cree que es un error comuniquese con soporte"
						extra={[
							<Button type="primary" key="console" onClick={() => history.replace("/login")}>
        Ir al inicio
							</Button>
						]}
					/>
			}
		</>
	);
};

export default VerifyAsesor;
