
import {INIT_URL, SPECIALTIES_SELECT_SET, ESPECIALTY_ACTION, SPECIALTY_SET } from "../../constants/ActionTypes";

const INIT_STATE = {
	initURL: "",
	specialties: null,
	action: [[],[]],
	specialty: null,
};

export default (state = INIT_STATE, action) => {
  
	switch (action.type) {

	case INIT_URL: {
		return {...state, initURL: action.payload};
	}

	case SPECIALTIES_SELECT_SET: {
		return {
			...state,
			specialties: action.payload,
		};
	}

	case SPECIALTY_SET: {
		return {
			...state,
			specialty: action.payload,
		};
	}

	case ESPECIALTY_ACTION: {
		return {
			...state,
			action: action.payload,
		};
	}

	default:
		return state;
	}
};
