import {FETCH_START_CHARGES, SET_RESET_CHARGES, SET_CHARGES, FETCH_SUCCESS_CHARGES, SET_ACTION_CHARGES, FETCH_ERROR_CHARGES, SET_PRE_CHARGES} from "../../constants/ActionTypes";

const INIT_STATE = {
	error: "",
	loading: false,
	message: "",
	response: null,
	action: "",
	charges: null,
	precharges: []
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
	case FETCH_START_CHARGES: {
		return {...state, error: "", message: "", loading: true, response: null};
	}
	case FETCH_SUCCESS_CHARGES: {
		return {...state, error: "", message: "", loading: false, response: null};
	}
	case SET_ACTION_CHARGES: {
		return {...state, action: action.payload};
	}
	case FETCH_ERROR_CHARGES: {
		return {...state, loading: false, error: action.payload, message: "", response: false};
	}
	case SET_CHARGES: {
		return {...state, charges: action.payload};
	}
	case SET_PRE_CHARGES: {
		return {...state, precharges: action.payload};
	}
	case SET_RESET_CHARGES: {
		return {...state, charges: [], precharges: []};
	}
	default:
		return state;
	}
};
