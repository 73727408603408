import { 
	SET_INVOICES, SET_ACTION_INVOICES, FETCH_START_INVOICES, FETCH_SUCCESS_INVOICES, FETCH_ERROR_INVOICES,
	SET_ACTION_INVOICES_MODAL,FETCH_ERROR_INVOICES_MODAL,FETCH_SUCCESS_INVOICES_MODAL, FETCH_START_INVOICES_MODAL } from "constants/ActionTypes";
import {INIT_URL} from "constants/ActionTypes";
  
const INIT_STATE = {
	initURL: "",
	invoices: null,
	error: "",
	loading: false,
	message: "",
	response: null,
	action: [[],[]],
};
  
export default (state = INIT_STATE, action) => {
    
	switch (action.type) {
  
	case INIT_URL: {
		return {...state, initURL: action.payload};
	}
	case SET_INVOICES: {
		return {
			...state,
			invoices: action.payload,
		};
	}
	case SET_ACTION_INVOICES: {
		return {
			...state,
			action: action.payload,
		};
	}
	case FETCH_START_INVOICES: {
		return {...state, error: "", message: "", loading: true, response: null, action: ""};
	}
	case FETCH_SUCCESS_INVOICES: {
		return {...state, error: "", message: "", loading: false, response: null, action: ""};
	}
	case FETCH_ERROR_INVOICES: {
		return {...state, loading: false, error: action.payload, message: "", response: false, action: ""};
	}
	case FETCH_START_INVOICES_MODAL: {
		return {...state, error_modal: "", message_modal: "", loading_modal: true, response_modal: null};
	}
	case FETCH_SUCCESS_INVOICES_MODAL: {
		return {...state, error_modal: "", message_modal: "", loading_modal: false, response_modal: null};
	}
	case FETCH_ERROR_INVOICES_MODAL: {
		return {...state, loading_modal: false, error_modal: action.payload, message_modal: "", response_modal: false};
	}
	case SET_ACTION_INVOICES_MODAL: {
		return {
			...state,
			action_modal: action.payload,
		};
	}
	default:
		return state;
	}
};