import React, {useEffect, useState} from "react";
import { Steps, Cascader, Button, message, Card, Row, Col, Input, Form, Skeleton, Select, Divider, DatePicker, Result, Upload, Modal, notification  } from "antd";
import { useParams } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import { resetActionCommon } from "../appRedux/actions/Common";
import { UserOutlined, SolutionOutlined, FileProtectOutlined, SaveOutlined, MinusCircleOutlined, ScheduleOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { getCitiesSelect } from "appRedux/actions/Cities";
import {getGendersSelect} from "appRedux/actions/Genders";
import {initCurriculumDoctor} from "appRedux/actions/Curriculums";
import {getTrainingLevelsSelect} from "appRedux/actions/TrainingLevels";
import {getEducationLevelsSelect} from "appRedux/actions/EducationLevels";
import {getSpecialtiesSelect} from "appRedux/actions/Specialties";
import {Scrollbars} from "react-custom-scrollbars";
import {beforeUploadImage, getBase64} from "util/Generic";
import { getActivationDoctor } from "appRedux/actions/Auth";
import { useHistory} from "react-router-dom";
import moment from "moment";
import { beforeCurrentDate } from "../util/Generic";

const { Step } = Steps;

const VerifyDoctor = () => {
	const { token } = useParams();
	const dispatch = useDispatch();
	const [current, setCurrent] = React.useState(0);
	const [fileList, setFileList] = React.useState();
	const [fileListProfile, setFileListProfile] = React.useState();
	const [previewImage, setPreviewImage] = React.useState();
	const [previewVisible, setPreviewVisible] = React.useState(false);
	const history = useHistory();
	const [form] = Form.useForm();
	//Declare all variable for use
	const cities = useSelector(({cities}) => cities.cities);
	const genders = useSelector(({genders}) => genders.genders);
	const educations = useSelector(({educationLevels}) => educationLevels.educations);
	const trainings = useSelector(({trainingLevels}) => trainingLevels.trainings);
	const specialties = useSelector(({specialties}) => specialties.specialties);
	const representante = localStorage.getItem("temp");

	//Initialize the fields form
	const initialFormState1 = { id: token, name: "", city: "", address: "", phone: "", telephone: "", 
		birthday: "", email: "", gender: "", email1: "" };
  
	const initialFormStateG = {  };

	const educationsArray = [
		{}
	];

	const certificationsArray = [
		{}
	];

	const experiencesArray = [
		{}
	];

	//Responses
	const response = useSelector(({common}) => common.response);
	const action = useSelector(({common}) => common.action);
	const error = useSelector(({common}) => common.error);
	const loading = useSelector(({common}) => common.loading);
	const [type, setType] = useState(false);
	const [errorActi, setErrorActi] = useState(false);
	const [loadingS, setLoadingS] = useState(false);

	useEffect(() => {
    
		if(response && action=="doctor_activation"){
			setType(true);
			message.success("Cuenta activada exitosamente");
			setLoadingS(false);
		}
		dispatch(resetActionCommon());
	}, [response]);

	const errorM =(er) => (
		<p>Ocurrió un error al procesar la solicitud, por favor intente nuevamente</p>
	);

	useEffect(() => {
		if (error && loadingS){
			setLoadingS(false);
			notification.open({
				message: "Error",
				type: "error",
				description: errorM(error)
			});
		}else if(error && !loadingS){
			setErrorActi(true);
			message.info(error);
			setLoadingS(false);
		}       
	}, [error]);
  
   
	//FORMS
	const [form1, setForm1] = React.useState(initialFormState1);
	const [form2, setForm2] = React.useState(educationsArray);
	const [form3, setForm3] = React.useState(certificationsArray);
	const [form4, setForm4] = React.useState(experiencesArray);

	const dateFormat = "YYYY-MM-DD";

  

	//Modal
	const next = () => {

		if(current == 0){
			form.validateFields()
				.then(values => {
          
					setForm1(values);
					setCurrent(current + 1);
				})
				.catch(() => {
				}); 
		}else if(current == 1){
			form.validateFields()
				.then(values => {
					if(values.educations.length > 0){
						setForm2(values);
						setCurrent(current + 1);
					}else{
						message.error("Debe ingresar al menos un registro en está sección");
					}
				})
				.catch(() => {
				}); 
		}else if(current == 2){
			form.validateFields()
				.then(values => {
					setForm3(values);
					setCurrent(current + 1);
				})
				.catch(() => {
				}); 
		}else if(current == 3){
			form.validateFields()
				.then(values => {
					if(values.experiences.length > 0){
						setForm4(values);
						setCurrent(current + 1);
					}else{
						message.error("Debe ingresar al menos un registro en está sección");
					}
				})
				.catch(() => {
				}); 
		} 
       

		//setCurrent(current + 1);
    
	};

	const prev = () => {
		setCurrent(current - 1);
	};

	const finishValidation = async() => {
    
		await form.validateFields()
			.then(values => {
				//console.log(values);
				//setForm5(values);

				let data = {};
				data.F1 = form1;
				data.F2 = form2;
				data.F3 = form3;
				data.F4 = form4;
				data.F5 = values;

				if(values.specialties.length < 1){
					message.error("Debe seleccionar una especialidad");
					return;
				}

				setLoadingS(true);
				dispatch(initCurriculumDoctor(data, "doctor_activation"));
				//setCurrent(current + 1);
			})
			.catch(() => {
				notification.error("Lo sentimos ocurrió un error al momento de guardar tus datos, por favor intenta nuevamente");
			}); 

	};


	//GetCities
	useEffect(() => {
		//Get all cities for select
		dispatch(getActivationDoctor(token, ""));
		dispatch(getCitiesSelect());
		dispatch(getGendersSelect());
		dispatch(getTrainingLevelsSelect());
		dispatch(getEducationLevelsSelect());
		dispatch(getSpecialtiesSelect());
	}, []);
  
	//Formulario para medico: {token}

	const steps = [
		{
			title: "Información Personal",
			key: 0
		},
		{
			title: "Instrucción formal",
			key: 1
		},
		{
			title: "Certificación/Capacitación",
			key: 2
		},
		{
			title: "Experiencia",
			key: 3
		},
		{
			title: "General",
			key: 4
		},
	];

	const FormItem = Form.Item;

	const onFinishFailed = () => {
	};

	// eslint-disable-next-line no-unused-vars
	function onChangeD(date, dateString) {
		//form.setFieldsValue({ birthday: moment(dateString, 'YYYY-MM-DD') })
	}

	function handleChangeS1() {
	}

	const { Option } = Select;

	const formBasic = (
		<>
			<Form
				//initialValues={{valuesForm}}
				name="basic"
				onFinishFailed={onFinishFailed}
				className="gx-signin-form gx-form-row0"
				form={form}
				labelAlign="top"
				initialValues={form1}
			>

				<Row>
					<Col span={24}>
						<FormItem name="id" hidden>
							<Input placeholder=""/>
						</FormItem>
					</Col>

					<Col span={12}>
						<FormItem name="fullname" label="Usuario" labelCol={{ span: 24 }}>
							<Input placeholder={representante} disabled={true}/>
						</FormItem>
					</Col>

					<Col span={12}>
						<FormItem  name="city" label="Ciudad" labelCol={{ span: 24 }}
							rules={[
								{
									required: true,
									message: "Selecciona la ciudad de residencia!",
								},
							]}>
							<Cascader options={cities} placeholder="Ciudad de residencia" />
						</FormItem>
					</Col>

					<Col span={24}>
						<FormItem  name="address" label="Dirección de domicilio" labelCol={{ span: 24 }}
							rules={[
								{
									required: true,
									message: "Ingrese la dirección de domicilio!",
								},
							]}>
							<Input/>
						</FormItem>
					</Col>

					<Col span={8}>
						<FormItem  name="phone" label="Celular" labelCol={{ span: 24 }}
							rules={[
								{
									required: true,
									message: "Ingrese el celular!",
								},
								{
									max: 10,
									message: "Máximo 10 dígitos"
								}
							]}>
							<Input type="number" />
						</FormItem>
					</Col>

					<Col span={8}>
						<FormItem  name="telephone" label="Teléfono domicilio" labelCol={{ span: 24 }}
							rules={[
								{
									max: 9,
									message: "Máximo 9 dígitos"
								}
							]}> 
							<Input type="number" />
						</FormItem>
					</Col>

					<Col span={8}>
						<FormItem  name="birthday" label="Fecha de nacimiento" labelCol={{ span: 24 }}        
							rules={[
								{
									required: true,
									message: "Ingrese la fecha de nacimiento!",
								},
							]}>
							<DatePicker defaultPickerValue={moment().subtract('18', 'years')} disabledDate={(current) => {
							return moment().subtract('18', 'years') <= current;
						}} showToday={false} format={dateFormat} placeholder="Seleccione" style={{width: "100%"}} onChange={onChangeD} />
						</FormItem>
					</Col>

					<Col span={16}>
						<FormItem  name="email1" label="Correo recuperación" labelCol={{ span: 24 }}>
							<Input placeholder="Correo de recuperación" />
						</FormItem>
					</Col>

					<Col span={8}>
						<FormItem  name="gender" label="Género" labelCol={{ span: 24 }}
						>
							<Select placeholder="Seleccione el género" style={{ width: "100%" }} options={genders ? genders : []} onChange={handleChangeS1}>
							</Select>
						</FormItem>
					</Col>
            
          
				</Row>
			</Form>
		</>
	);


	const onFinish = () => {
	};

  

	const handleChangeImage = info => {
		
    
		if (info.file.status === "uploading") {
      
			//this.setState({ loading: true });
			return;
		}
		if (info.file.status === "done") {
			// Get this url from response in real world.
			/* getBase64(info.file.originFileObj, imageUrl =>
        this.setState({
          imageUrl,
          loading: false,
        }),
      ); */

			//setFileList([file])
		}

	};
  
	const formEducations = (
		<>
			<Form 
				name="education" 
				onFinish={onFinish} 
				autoComplete="off" 
				className="gx-signin-form gx-form-row0"
				form={form}    
			>
				<Form.List name="educations" initialValue={form2}>
					{(fields, { add, remove }) => (
						<>          
							{fields.map(field => (
								<div key={[field.fieldKey, "div"]}>
									<Row>
										<Col span="6">
											<Form.Item
												{...field}
												label="Nivel de instrucción"
												labelCol={{ span: 24 }}
												name={[field.name, "level"]}
												fieldKey={[field.fieldKey, "level"]}
												rules={[{ required: true, message: "Nivel Instrucción Obligatorio" }]}
											>
												<Select placeholder="Seleccione" options={educations ? educations : [] }>
												</Select>
											</Form.Item>
										</Col>
										<Col span="9">
                
											<Form.Item
												{...field}
												label="Formación"
												labelCol={{ span: 24 }}
												name={[field.name, "formacion"]}
												fieldKey={[field.fieldKey, "formacion"]}
												initialValue=''
												rules={[{ required: true, message: "Obligatorio" }]}
											>
												<Input placeholder="Ej. Medico General, Dentista, Etc." />
											</Form.Item>
										</Col>
										<Col span="9">
											<Form.Item
												{...field}
												label="Institución"
												labelCol={{ span: 24 }}
												name={[field.name, "institution"]}
												fieldKey={[field.fieldKey, "institution"]}
												rules={[{ required: true, message: "Ingrese la institución" }]}
											>
												<Input placeholder="¿Institución donde estudió?" />
											</Form.Item>
										</Col>
									</Row>

									<Row align="middle">
              

										<Col span="4">
											<Form.Item
												{...field}
												label="Fecha de inicio"
												labelCol={{ span: 24 }}
												name={[field.name, "date_ini"]}
												fieldKey={[field.fieldKey, "date_ini"]}
												rules={[{ required: true, message: "Seleccione la fecha de inicio" }]}
											>
												<DatePicker disabledDate={(current) => beforeCurrentDate(current)} showToday={false} style={{width: "100%"}}  placeholder="Seleccione" picker="month" />
											</Form.Item>
										</Col>

										<Col span="4">
											<Form.Item
												{...field}
												label="Fecha finalización"
												labelCol={{ span: 24 }}
												name={[field.name, "date_end"]}
												fieldKey={[field.fieldKey, "date_end"]}
												//rules={[{ required: true, message: "Seleccione la fecha finalización" }]}
												
												dependencies={["educations", Number(field.key), "date_ini"]}
												rules={[{ required: true, message: "Seleccione la fecha finalización" },
													({ getFieldValue }) => ({
														validator(_, value) {
															// eslint-disable-next-line no-undef
															if(moment(getFieldValue(["educations", Number(field.fieldKey), "date_ini"])) < moment(value)){
																return Promise.resolve();
															}else{
																
																return Promise.reject("La fecha inicial no puede ser mayor a la final");
															}
                        
														},
													}),
												]}
											>
												<DatePicker disabledDate={(current) => beforeCurrentDate(current)}  showToday={false} style={{width: "100%"}}  picker="month" placeholder="Seleccione" />
											</Form.Item>
										</Col>

										<Col span="6">
											<Form.Item
												{...field}
												label="Ciudad"
												labelCol={{ span: 24 }}
												name={[field.name, "city"]}
												fieldKey={[field.fieldKey, "city"]}
												rules={[{ required: true, message: "Ingrese la ciudad" }]}
											>
												<Input placeholder="¿Ciudad donde estudió?" />
											</Form.Item>
										</Col>

										<Col span="5">
											<Form.Item
												{...field}
												label="Número de Registro"
												labelCol={{ span: 24 }}
												name={[field.name, "registry"]}
												fieldKey={[field.fieldKey, "registry"]}
												tooltip="Para Ecuador lo encuentra en la página oficial de la senecyst"
												//initialValue={`${process.env.REACT_APP_TERCER_NIVEL_ID}`}
												//initialValue={JSON.stringify(form.getFieldValue(['educations', Number(field.key), 'formacion']))}
												dependencies={["educations", Number(field.key), "level"]}
												//shouldUpdate
												//rules={form.getFieldValue(['educations', Number(field.key), 'formacion']) == '93f09fc766574cd29bbf629d0a7d9385' ? [{ required: true, message: 'Ingrese la ciudad' }] :null}
												rules={[
													({ getFieldValue }) => ({
														validator(_, value) {
															// eslint-disable-next-line no-undef
															if(getFieldValue(["educations", Number(field.fieldKey), "level"]) != `${process.env.REACT_APP_TERCER_NIVEL_ID}`){
																return Promise.resolve();
															}else{
																if (value != null) {
																	return Promise.resolve();
																}
																return Promise.reject("Obligatorio");
															}
                        
														},
													}),
												]}
											>
												<Input placeholder="Numero de registro senescyt" />
											</Form.Item>
										</Col>

										<Col span="4" align="center">
											<Form.Item
												{...field}
												label="Adjuntar Título"
												labelCol={{ span: 24 }}
												name={[field.name, "url_title"]}
												fieldKey={[field.fieldKey, "url_title"]}
												initialValue={null}
												tooltip="Formatos permitidos: png, jpg, pdf. Máximo 2mb"

											>
												<Upload 
													maxCount={1}
													name="imas"
													fileList={fileList}
													// eslint-disable-next-line no-undef
													action={`${process.env.REACT_APP_API}upload`}
													beforeUpload={beforeUploadImage}
													onChange={handleChangeImage}
												>
													<Button icon={<UploadOutlined  />} >Subir</Button>
												</Upload>
											</Form.Item>
										</Col>

										<Col span="1" align="center">
											<MinusCircleOutlined style={{color: "red"}} onClick={() => remove(field.name)} />
										</Col>

									</Row>
									<Divider />
                              
								</div>
							))}
							<Col span={8} offset={16}>
								<Form.Item>
									<Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                Añadir Instruccion Formal
									</Button>
								</Form.Item>
							</Col>
            
						</>
					)}
				</Form.List>
			</Form>
		</>
	);

	const formCertification = (
		<>
			<Form 
				name="certification" 
				onFinish={onFinish} 
				autoComplete="off" 
				className="gx-signin-form gx-form-row0"
				form={form}
			>
				<Form.List name="certifications" key="form3" initialValue={form3}>
					{(fields, { add, remove }) => (
						<>          
							{fields.map(field => (
								<div key={[field.fieldKey, "divcert"]}>

									<Row>
										<Col span="6">
											<Form.Item
												{...field}
												label="Tipo"
												labelCol={{ span: 24 }}
												name={[field.name, "type"]}
												fieldKey={[field.fieldKey, "type"]}
												rules={[{ required: true, message: "Tipo Obligatorio" }]}
											>
												<Select placeholder="Seleccione" options={trainings ? trainings : []}>
												</Select>
											</Form.Item>
										</Col>
										<Col span="9">
                
											<Form.Item
												{...field}
												label="Nombre del evento"
												labelCol={{ span: 24 }}
												name={[field.name, "name_event"]}
												fieldKey={[field.fieldKey, "name_event"]}
												initialValue=''
											>
												<Input placeholder="Semana de la salud, etc" />
											</Form.Item>
										</Col>

										<Col span="9">
											<Form.Item
												{...field}
												label="Cetificado"
												labelCol={{ span: 24 }}
												name={[field.name, "certificate"]}
												fieldKey={[field.fieldKey, "certificate"]}
												rules={[{ required: true, message: "Certificado obligatorio" }]}
											>
												<Select placeholder="Seleccione">
													<Option value="0">Aprobacion</Option>
													<Option value="1">Asistencia</Option>
												</Select>
											</Form.Item>
										</Col>
									</Row>

									<Row align="middle">
              

										<Col span="6">
											<Form.Item
												{...field}
												label="Fecha desde"
												labelCol={{ span: 24 }}
												name={[field.name, "date_ini_cert"]}
												fieldKey={[field.fieldKey, "date_ini_cert"]}
												rules={[{ required: true, message: "Seleccione la fecha de inicio" }]}
											>
												<DatePicker disabledDate={(current) => beforeCurrentDate(current)} showToday={false} style={{width: "100%"}}  placeholder="Seleccione" />
											</Form.Item>
										</Col>

										<Col span="6">
											<Form.Item
												{...field}
												label="Fecha finalización"
												labelCol={{ span: 24 }}
												name={[field.name, "date_end_cert"]}
												fieldKey={[field.fieldKey, "date_end_cert"]}
												//rules={[{ required: true, message: "Seleccione la fecha finalización" }]}
												dependencies={["certifications", Number(field.key), "date_ini_cert"]}
												//shouldUpdate
												//rules={form.getFieldValue(['educations', Number(field.key), 'formacion']) == '93f09fc766574cd29bbf629d0a7d9385' ? [{ required: true, message: 'Ingrese la ciudad' }] :null}
												rules={[{ required: true, message: "Seleccione la fecha finalización" },
													({ getFieldValue }) => ({
														validator(_, value) {
															// eslint-disable-next-line no-undef
															if(moment(getFieldValue(["certifications", Number(field.fieldKey), "date_ini_cert"])) < moment(value)){
																return Promise.resolve();
															}else{
																return Promise.reject("La fecha de finalización no puede ser mayor a la inicial");
															}
                        
														},
													}),
												]}
											>
												<DatePicker disabledDate={(current) => beforeCurrentDate(current)} showToday={false} style={{width: "100%"}} placeholder="Seleccione" />
											</Form.Item>
										</Col>

										<Col span="6">
											<Form.Item
												{...field}
												label="Numero de horas"
												labelCol={{ span: 24 }}
												name={[field.name, "hours"]}
												fieldKey={[field.fieldKey, "hours"]}
												rules={[{ required: true, message: "Ingrese el número de horas" }]}
											>
												<Input placeholder="Número de horas?" />
											</Form.Item>
										</Col>

										<Col span="5" align="center">
											<Form.Item
												{...field}
												label="Adjuntar certificado"
												labelCol={{ span: 24 }}
												name={[field.name, "url_certified"]}
												fieldKey={[field.fieldKey, "url_certified"]}
												initialValue={null}
												tooltip="Formatos permitidos: png, jpg, pdf. Máximo 2mb"
											>
												<Upload maxCount={1}
													name="imas"
													fileList={fileList}
													className="avatar-uploader"
													// eslint-disable-next-line no-undef
													action={`${process.env.REACT_APP_API}upload`}
													beforeUpload={beforeUploadImage}
													onChange={handleChangeImage} >
													<Button icon={<UploadOutlined  />} >Subir</Button>
												</Upload>
											</Form.Item>
										</Col>

										<Col span="1" align="center">
											<MinusCircleOutlined style={{color: "red"}} onClick={() => remove(field.name)} />
										</Col>

									</Row>
									<Divider />
              
								</div>
							))}
							<Col span={8} offset={16}>
								<Form.Item>
									<Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                Añadir Capacitación
									</Button>
								</Form.Item>
							</Col>
            
						</>
					)}
				</Form.List>
			</Form>
    
		</>
	);

	const formExperience = (
		<>
			<Form name="experience" onFinish={onFinish} autoComplete="off" className="gx-signin-form gx-form-row0" form={form}>
				<Form.List name="experiences" key="form4" initialValue={form4}>
					{(fields, { add, remove }) => (
						<>          
							{fields.map(field => (
								<div key={[field.fieldKey, "divFE"]}>

									<Row>
              
										<Col span="12">
											<Form.Item
												{...field}
												label="Cargo"
												labelCol={{ span: 24 }}
												name={[field.name, "position"]}
												fieldKey={[field.fieldKey, "position"]}
												rules={[{ required: true, message: "El cargo es obligatorio" }]}
											>
												<Input placeholder="Cargo que desempeñó" />
											</Form.Item>
										</Col>

										<Col span="12">
                
											<Form.Item
												{...field}
												label="Institución"
												labelCol={{ span: 24 }}
												name={[field.name, "institutionFE"]}
												fieldKey={[field.fieldKey, "institutionFE"]}
												rules={[{ required: true, message: "Ingrese la institución" }]}
											>
												<Input placeholder="¿Institución donde laboró?" />
											</Form.Item>
										</Col>

									</Row>

									<Row align="top">

										<Col span="6">
											<Form.Item
												{...field}
												label="Fecha de inicio"
												labelCol={{ span: 24 }}
												name={[field.name, "date_iniFE"]}
												fieldKey={[field.fieldKey, "date_iniFE"]}
												rules={[{ required: true, message: "Fecha inicio obligatoria" }]}
											>
												<DatePicker disabledDate={(current) => beforeCurrentDate(current)} placeholder="Seleccione" style={{width: "100%"}} picker="month" />
											</Form.Item>
										</Col>

										<Col span="6">
											<Form.Item
												{...field}
												label="Fecha finalización"
												labelCol={{ span: 24 }}
												name={[field.name, "date_endFE"]}
												fieldKey={[field.fieldKey, "date_endFE"]}
												help="Dejar en blanco si trabaja actualmente"
												initialValue=''
												dependencies={["experiences", Number(field.key), "date_iniFE"]}
												rules={[
													({ getFieldValue }) => ({
														validator(_, value) {
															// eslint-disable-next-line no-undef
															if(moment(getFieldValue(["experiences", Number(field.fieldKey), "date_iniFE"])) < moment(value) || value == ""){
																return Promise.resolve();
															}else{																
																return Promise.reject("La fecha de finalización no puede ser mayor a la inicial");
															}
                        
														},
													}),
												]}
											>
												<DatePicker disabledDate={(current) => beforeCurrentDate(current)} placeholder="Seleccione" style={{width: "100%"}}  picker="month" />
											</Form.Item>
										</Col>

										<Col span="12">
											<Form.Item
												{...field}
												label="Ciudad"
												labelCol={{ span: 24 }}
												name={[field.name, "cityFE"]}
												fieldKey={[field.fieldKey, "cityFE"]}
												rules={[{ required: true, message: "Ciudad Obligatoria" }]}
												initialValue={null}
											>
												<Input placeholder="¿Ciudad donde laboró?" />
											</Form.Item>
										</Col>

             

									</Row>

									<Row align="middle">

										<Col span="23">
											<Form.Item
												{...field}
												label="Descripción del cargo"
												labelCol={{ span: 24 }}
												name={[field.name, "descriptionFE"]}
												fieldKey={[field.fieldKey, "descriptionFE"]}
												rules={[{ required: true, message: "Ingrese una descripción" }]}
											>
												<Input placeholder="¿Breve descripción del cargo?" />
											</Form.Item>
										</Col>

										<Col span="1" align="center">
											<MinusCircleOutlined style={{color: "red"}} onClick={() => remove(field.name)} />
										</Col>

									</Row>
									<Divider />
								</div>
							))}
							<Col span={8} offset={16}>
								<Form.Item>
									<Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                Añadir información
									</Button>
								</Form.Item>
							</Col>
            
						</>
					)}
				</Form.List>
			</Form>
		</>
	);
     
	const handleChangeImagePerfil = info => {
		let {file} = info;
    
		if (info.file.status === "uploading") {
     
			//this.setState({ loading: true });
			return;
		}
		if (info.file.status === "done") {
			// Get this url from response in real world.
			/* getBase64(info.file.originFileObj, imageUrl =>
        this.setState({
          imageUrl,
          loading: false,
        }),
      ); */

			setFileListProfile([file]);
		}

	};

	const handleRemove = () => {
		setFileListProfile([]);
	};

	const handlePreview = async file => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj);
		}
		setPreviewVisible(true);
		setPreviewImage(file.url || file.preview);

	};

	function handleChange() {
	}

	function handleCancel(){
		setPreviewVisible(false);
		setPreviewImage();
	}

	const formGeneral = (
		<>
			<Form
				//initialValues={{valuesForm}}
				name="basic1"
				onFinishFailed={onFinishFailed}
				className="gx-signin-form gx-form-row0"
				form={form}
				labelAlign="top"

			>

				<Row>
					<Col span={24} >
						{
							<FormItem name="specialties" label="Áreas de salud"
								labelCol={{ span: 24 }}
								initialValue={[]}
							>
								<Select
									mode="multiple"
									allowClear
									style={{ width: "100%" }}
									placeholder="Seleccionar especialidad"
									onChange={handleChange}
									options={specialties}
									filterOption={(input, option) =>
										option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
								>
								</Select>
							</FormItem>}
					</Col>
            
					<Col span={24} >
						<Form.Item
							label="Foto de Perfil"
							labelCol={{ span: 24 }}
							name='url_perfil'
							initialValue={null}
						>
							<Upload 
								listType="picture-card"
								name="imas"
								fileList={fileListProfile}
								className="avatar-uploader"
								// eslint-disable-next-line no-undef
								action={`${process.env.REACT_APP_API}upload`}
								beforeUpload={beforeUploadImage}
								onChange={handleChangeImagePerfil}
								onRemove={handleRemove}
								onPreview={handlePreview}
							>
								<UploadOutlined  /> Subir
							</Upload>
						</Form.Item>
					</Col>
          
					<Col span={24} >
						<Form.Item
							label="Descripción para su perfil"
							labelCol={{ span: 24 }}
							name='description'
							initialValue={null}
						>
							<Input.TextArea />
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</>
	);

	return (
		<Scrollbars autoHide >   
			<Modal
				visible={previewVisible}
				title="Preview"
				footer={null}
				onCancel={handleCancel}
			>
				<img alt="example" style={{ width: "100%" }} src={previewImage} />
			</Modal>
			{loading && !loadingS ? <Skeleton /> : !type ? errorActi ? <Result
				status="error"
				title="Error al activar cuenta!"
				subTitle="El enlace expiró o no es el correcto"
				extra={[
					<Button type="primary" key="console">
						<a onClick={() => history.replace("/signin")}>Ir al inicio</a>
					</Button>,
				]}
        
			/> :
				<Row justify="center" align="middle">
					<Col span={21} offset={3}>
						<Card title="Validación datos médico" >
							<Steps current={current}>
								{steps.map(item => (
									<Step key={item.title} title={item.title} icon={ item.key == "0" ? <UserOutlined /> : item.key == "1" ? <SolutionOutlined />: item.key == "2" ? <FileProtectOutlined /> : item.key == "3" ? <ScheduleOutlined /> : <SaveOutlined />} />
								))}
							</Steps>
							<Divider />
							<div className="steps-content">
								{steps[current].key == "0" ? formBasic : steps[current].key == "1" ? formEducations : steps[current].key =="2" ? formCertification : steps[current].key == "3" ? formExperience : steps[current].key == "4" ? formGeneral : "Hubo un error!"}
							</div>
							<div className="steps-action">
								{current < steps.length - 1 && (
									<Button type="primary" onClick={() => next()}>
              Siguiente
									</Button>
								)}
								{current === steps.length - 1 && (
									<Button loading={loadingS} type="primary" onClick={() => finishValidation()}>
              Finalizar
									</Button>
								)}
								{current > 0 && (
									<Button disabled={loadingS}  onClick={() => prev()}>
              Atrás
									</Button>
								)}
							</div>
						</Card>
					</Col>
				</Row> : <Result
				status="success"
				title="Cuenta en proceso de verificación!"
				subTitle="En estos momentos verificaremos la informacíon proporcionada, se informará mediante correo electrónico la activación"
				extra={[
					<Button type="primary" key="console">
						<a onClick={() => history.replace("/signin")}>Ir al inicio</a>
					</Button>,
				]}
			/>
			}
		</Scrollbars>
	);
};

export default VerifyDoctor;
