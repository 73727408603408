import {FETCH_START_REFERS, SET_REFERS, SET_REFERS_INVITATION, FETCH_SUCCESS_REFERS, SET_ACTION_REFERS, FETCH_ERROR_REFERS, SET_REFERS_REPORT} from "../../constants/ActionTypes";

const INIT_STATE = {
	error: "",
	loading: false,
	message: "",
	response: null,
	action: "",
	refers: null,
	refers_report: null,
	refers_invitation: null
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
	case FETCH_START_REFERS: {
		return {...state, error: "", message: "", loading: true, response: null};
	}
	case FETCH_SUCCESS_REFERS: {
		return {...state, error: "", message: "", loading: false, response: null};
	}
	case SET_ACTION_REFERS: {
		return {...state, action: action.payload};
	}
	case FETCH_ERROR_REFERS: {
		return {...state, loading: false, error: action.payload, message: "", response: false};
	}
	case SET_REFERS: {
		return {...state, refers: action.payload};
	}
	case SET_REFERS_REPORT: {
		return {...state, refers_report: action.payload};
	}
	case SET_REFERS_INVITATION: {
		return {...state, refers_invitation: action.payload};
	}
	default:
		return state;
	}
};
