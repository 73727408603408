import React, { useState, useEffect } from "react";
import {Button, Checkbox, Col, Form, Input, message, Progress, Row, notification } from "antd";
import {Link, useHistory} from "react-router-dom";

import {useDispatch, useSelector} from "react-redux";
import IntlMessages from "util/IntlMessages";
import {validarCedula, checkPassword} from "util/CustomValidators";
import { userSignUpRefer } from "../../appRedux/actions/Users";
import { resetActionCommon } from "../../appRedux/actions/Common";

const FormItem = Form.Item;

const TabUser = ({token}) => {

	const [valuePassword, setValuePassword] = useState(0);
	const dispatch = useDispatch();
	const history = useHistory();
	const [form] = Form.useForm();
	const [selectTerms, setSelectTerms] = useState(false);

	//Responses redux commos
	const response = useSelector(({common}) => common.response);
	const action = useSelector(({common}) => common.action);
	const error = useSelector(({common}) => common.error);
	const loading = useSelector(({common}) => common.loading);

	//Initialize the fields form
	const initialFormState = {firstname: '', lastname: '', email: '', 
		identification: '', password: '', password_confirm: '' }
	const [valuesForm, setValuesForm] = useState(initialFormState);

	useEffect(() => {
		if(response && action=='refer_add'){
			message.success("Registro como asesor exitoso");
			form.resetFields();
			dispatch(resetActionCommon());
			history.push("/signin");
		}
	}, [response]);


	const errorM =(er) => (
		<div>
			{er ? <p>Los siguientes datos ya se encuentran registrados: </p>:null}
			{er ? er.map((error, index) => <p key={index}>{error}</p>): null}
			{er ? <p>Intenta recuperando la cuenta</p>:null}
		</div>
	);

      
	useEffect(() => {
		if (error) {
			notification.open({
				message: 'Error',
				type: "error",
				description: errorM(error)
			});
		}       
	}, [error]);

	const onFinishFailed = errorInfo => {
		//console.log('Failed:', errorInfo);
	};

	const onSubmit = () => {
		if(selectTerms){
			form
				.validateFields()
				.then((values) => {
					//form.resetFields();
					//onCreate(values);
					dispatch(userSignUpRefer(values, 'refer_add'));  
				})
				.catch((info) => {
					console.log('Validate Failed:', info);
				});
		}else{
			message.error("Por favor selecciona los terminos y condiciones");
		}
        
	}

	const changeTerms = () => {
		setSelectTerms(!selectTerms);
	}

	return (
		<Form
			initialValues={{valuesForm}}
			name="basic"
			onFinishFailed={onFinishFailed}
			className="gx-signin-form gx-form-row0"
			form={form}
		>

			<Row>
				<FormItem hidden name="token" initialValue={token}>
					<Input />
				</FormItem>

				{/* <Col span={12}>
                <FormItem rules={[{required: true, message: 'Por favor ingresa el nombre'}]} name="firstname">
                    <Input placeholder="Nombre"/>
                </FormItem>
            </Col>
            
            <Col span={12}>
                <FormItem rules={[{required: true, message: 'Por favor ingresa el apellido!'}]} name="lastname">
                    <Input placeholder="Apellido"/>
                </FormItem>
            </Col>

            <Col span={24}>
                <FormItem name="identification"
                rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese el DNI o la cédula',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (validarCedula(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject('Cédula no válida!');
                      },
                    }),
                  ]}
                >
                    <Input placeholder="DNI/Cédula"/>
                </FormItem>
            </Col>
            
            <Col span={24}>
                <FormItem name="email" rules={[{
                required: true, type: 'email', message: 'Correo inválido',
                }]}>
                    <Input placeholder="Correo"/>
                </FormItem>
            </Col>
            
            <Col span={24}>
                <Form.Item
                    name="password"
                    rules={[
                    {
                        required: true,
                        message: 'Por favor ingresa la contraseña',
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                        var state = checkPassword(value);
                        setValuePassword(state);
                        if (state > 50) {
                            return Promise.resolve();
                        }
                        return Promise.reject('Contraseña muy debil');
                        },
                    }),
                    ]}
                    hasFeedback
                >
                    <Input.Password placeholder="Contraseña" />
                </Form.Item>
                { valuePassword != 0 ? <Progress strokeColor={{
                    '0%': '#f5222d',
                    '100%': '#002766',
                }} percent={valuePassword} style={{top: 0}} /> 
                : null}
            </Col>

            <Col span={24}>
                <Form.Item
                name="password_confirm"
                dependencies={['password']}
                hasFeedback
                rules={[
                {
                    required: true,
                    message: 'Por favor confirma la contraseña!',
                },
                ({ getFieldValue }) => ({
                    validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                    }
                    return Promise.reject('Las contraseñas ingresadas no coinciden');
                    },
                }),
                ]}
                >
                <Input.Password placeholder="Confirmar contraseña" />
                </Form.Item>
            </Col> */}
            
            
				<Col span={24}>
					<Form.Item>
						<Checkbox checked={selectTerms} onChange={changeTerms}><IntlMessages id="appModule.iAccept"/></Checkbox>
						<a target="__blank" href="https://landing.novacita.com/terminos-y-condiciones"><span className="gx-signup-form-forgot gx-link"><IntlMessages
							id="appModule.termAndCondition"/></span></a>
					</Form.Item>
				</Col>
            
				<Col span={24}>
					<FormItem>
						<Button type="primary" className="gx-mb-0" loading={loading} onClick={onSubmit}>
							<IntlMessages id="app.userAuth.signUp"/>
						</Button>
						<span><IntlMessages id="app.userAuth.or"/></span> <Link to="/signin">Ir al inicio</Link>
					</FormItem>
				</Col>
			</Row>
		</Form>
	);
}

export default TabUser;