
import {INIT_URL, BUSINESS_RESET_ACTION, BUSINESS_ACTION, BUSINESS_SET, BUSINESSES_SET, BUSINESSES_EMPLOYES_SET, FETCH_START_BUSINESS, FETCH_SUCCESS_BUSINESS, FETCH_ERROR_BUSINESS } from "../../constants/ActionTypes";

const INIT_STATE = {
	initURL: "",
	businesses: null,
	action: [[],[]],
	business: null,
	employes: null,
	error: "",
	loading: false,
	response: null,
	message: ""
};

export default (state = INIT_STATE, action) => {
  
	switch (action.type) {

	case INIT_URL: {
		return {...state, initURL: action.payload};
	}

	case BUSINESSES_SET: {
		return {
			...state,
			businesses: action.payload,
		};
	}

	case BUSINESSES_EMPLOYES_SET: {
		return {
			...state,
			employes: action.payload,
		};
	}

	case BUSINESS_SET: {
		return {
			...state,
			business: action.payload,
		};
	}

	case BUSINESS_ACTION: {
		return {
			...state,
			action: action.payload,
		};
	}

	case BUSINESS_RESET_ACTION: {
		return {...state, error: "", message: "", loading: false, response: null, action: [[],[]]};
	}

	case FETCH_START_BUSINESS: {
		return {...state, error: "", message: "", loading: true, response: null};
	}

	case FETCH_SUCCESS_BUSINESS: {
		return {...state, error: "", message: "", loading: false, response: null};
	}

	case FETCH_ERROR_BUSINESS: {
		return {...state, loading: false, error: action.payload, message: "", response: false};
	}

	default:
		return state;
	}
};
