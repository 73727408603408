
import axios from "./Api";

export async function fetchMyBusiness(){    
	const data = await axios.get("businesseses/my").then(({data}) => {
		return data;
	}).catch(function () {
		return false;   
	});
	return data;
}


export async function verifyInvitation(token){    
	const data = await axios.get("businesses/invitation/"+token).then(({data}) => {
		return data;    
	}).catch(function () {
		return false;   
	});
	return data;
}

export async function getAppointments(employe){    
	const data = await axios.get("appointments/getDays/"+employe).then(({data}) => {
		return data;    
	}).catch(function () {
		return false;   
	});
	return data;
}

export async function getAppointmentsDoctor(employe){    
	const data = await axios.get("appointments/doctor/"+employe).then(({data}) => {
		return data;    
	}).catch(function () {
		return false;   
	});
	return data;
}

export async function getAppointment(id){    
	const data = await axios.get("appointments/"+id).then(({data}) => {
		return data.appointment;    
	}).catch(function () {
		return false;   
	});
	return data;
}

export async function fetchCities(){    
	const data = await axios.get("cities/select").then(({data}) => {
		return data.cities;
	}).catch(function () {
		return false;   
	});
	return data;
}

export async function fetchPacients(){    
	const data = await axios.get("prescriptions/pacients/select").then(({data}) => {
		return data.pacients;
	}).catch(function () {
		return false;   
	});
	return data;
}

export async function fetchMedicines(page, sort, field, take){    
	const data = await axios.get("medicines?page="+page+"&take="+take+"&sort="+sort+"&field="+field).then(({data}) => {
		return data.medicines;
	}).catch(function () {
		return false;   
	});
	return data;
}

export async function fetchMedicinesSelect(value){    
	const data = await axios.get("medicines/select/"+value).then(({data}) => {
		return data.medicines;
	}).catch(function () {
		return false;   
	});
	return data;
}

export async function fetchHistory(paciente){    
	let datas = {};
	datas.paciente = paciente;
	const data = await axios.post("histories/verify", datas).then(({data}) => {
		if(data.result){
			return data.history;
		}
		return false;
	}).catch(function () {
		return false;   
	});
	return data;
}

export async function fetchHistoryAllow(paciente){  
	let datas = {};
	datas.paciente = paciente;
	const data = await axios.post("histories/allow",datas).then(({data}) => {
		return data.result;
	}).catch(function () {
		return false;   
	});
	return data;
}

export async function fetchHistoryCheck(paciente, code){  
	let datas = {};
	datas.paciente = paciente;
	datas.code = code;
	const data = await axios.post("histories/check",datas).then(({data}) => {
		if(data.result){
			return data.history;
		}
		return false;
	}).catch(function () {
		return false;   
	});
	return data;
}

export async function fetchHistoryBasic(paciente){  
	let datas = {};
	datas.paciente = paciente;
	const data = await axios.post("histories/basic",datas).then(({data}) => {
		if(data.result){
			return data.history[0];
		}
		return false;
	}).catch(function () {
		return false;   
	});
	return data;
}

export async function fetchHistoryClinic(doctor, id){  
	const data = await axios.get("histories/pacient/"+doctor+"/"+id).then(({data}) => {
		if(data.result){
			return data.history[0];
		}
		return false;
	}).catch(function () {
		return false;   
	});
	return data;
}

export async function fetchPrescription(id){  
	const data = await axios.get("prescriptions/"+id).then(({data}) => {
		if(data.result){
			return data.prescription[0];
		}
		return false;
	}).catch(function () {
		return false;   
	});
	return data;
}

export async function acceptInvitation(token){    
	const data = await axios.get("businesses/accept/invitation/"+token).then(({data}) => {
		return data.result;    
	}).catch(function () {
		return false;   
	});
	return data;
}

export async function declineInvitation(token){    
	const data = await axios.get("businesses/decline/invitation/"+token).then(({data}) => {
		return data.result;    
	}).catch(function () {
		return false;   
	});
	return data;
}

export async function inactiveEmploye(token){    
	const data = await axios.get("employes/inactivate/"+token).then(({data}) => {
		return data.result;    
	}).catch(function () {
		return false;   
	});
	return data;
}

export async function activeEmploye(token){    
	const data = await axios.get("employes/activate/"+token).then(({data}) => {
		return data.result;    
	}).catch(function () {
		return false;   
	});
	return data;
}

export async function getInfoCita(id, fecha){    
	const data = await axios.get("doctor/"+id+"/"+fecha).then(({data}) => {
		return data.result;
	}).catch(function () {
		return false;   
	});
	return data;
}

export async function storeReschedule(datos){    
	const data = await axios.post("doctor/reschedules", datos).then(({data}) => {
       
		if(data.result){
			return data.result;
		}else{
			return data.message;
		}
	}).catch(function () {
		return false;   
	});
	return data;
}

export async function getAsesors(){    
	const data = await axios.get("refers/select").then(({data}) => {
		return data.refers;
	}).catch(function () {
		return false;   
	});
	return data;
}

export async function verifyInvitationAsesor(token){    
	const data = await axios.get("invitationRefer/verify/"+token).then(({data}) => {
		return data;    
	}).catch(function () {
		return false;   
	});
	return data;
}

export async function searchDiagnosis(value){    
	const data = await axios.get("diagnosis?search="+value).then(({data}) => {
		return data.options;    
	}).catch(function () {
		return false;   
	});
	return data;
}

export async function fetchRoles(){    
	const data = await axios.get("roles/select").then(({data}) => {
		return data.roles;
	}).catch(function () {
		return false;   
	});
	return data;
}

export async function fetchRolesComision(){    
	const data = await axios.get("roles/comision").then(({data}) => {
		return data.roles;
	}).catch(function () {
		return false;   
	});
	return data;
}

export async function acceptReschedule(token){    
	const data = await axios.get("reschedule/accept/"+token).then(({data}) => {
		return data.result;    
	}).catch(function () {
		return false;   
	});
	return data;
}

export async function verifyReschedule(token){    
	const data = await axios.get("reschedule/invitation/"+token).then(({data}) => {
		return data;    
	}).catch(function () {
		return false;   
	});
	return data;
}

export async function addExams(datos){    	
	const data = await axios.post("exams", datos).then(({data}) => {
		if(data.result){
			return data.id;
		}
		return false;
	}).catch(function () {
		return false;   
	});
	return data;
}

export async function updateCertificate(datos){    	
	const data = await axios.put("certificate/update", datos).then(({data}) => {
		/* if(data.result){
			return data.id;
		} */
		return data.result;
	}).catch(function () {
		return false;   
	});
	return data;
}

export async function getBusinesses(){    	
	const data = await axios.get("newBusiness").then(({data}) => {
		if(data.result){
			return data.data;
		}
		return false;
	}).catch(function () {
		return false;   
	});
	return data;
}

export async function getJournalTime(id){    	
	const data = await axios.get("schedules/default/"+id).then(({data}) => {
		if(data.result){
			return data;
		}
		return false;
	}).catch(function () {
		return false;   
	});
	return data;
}


export async function sendEmailVerification(datos){    	
	const data = await axios.post("email/verification", datos).then(({value}) => {
		return value;
	}).catch(function () {
		return false;   
	});
	return data;
}

export async function storeBlock(datos){    
	const data = await axios.post("blocked", datos).then(({data}) => {
       
		if(data.result){
			return data.result;
		}else{
			return data.message;
		}
	}).catch(function () {
		return false;   
	});
	return data;
}