import {
	FETCH_ERROR_CURRICULUM,
	FETCH_START_CURRICULUM,
	FETCH_SUCCESS_CURRICULUM,
	INIT_URL,
	FETCH_ERROR,
	FETCH_START,
	FETCH_SUCCESS,
	FETCH_ACTION,
	SET_CURRICULUM
} from "../../constants/ActionTypes";
import axios from "util/Api";
  
export const setInitUrl = (url) => {
	return {
		type: INIT_URL,
		payload: url
	};
};

export const initCurriculumDoctor = (datos, action) =>  {
	return (dispatch) => {
		dispatch({type: FETCH_START});
		axios.post("curriculums/after", datos
		).then(({data}) => {
			if (data.result) {
				dispatch({type: FETCH_SUCCESS});
				dispatch({type: FETCH_ACTION, payload: action});
			} else {
				dispatch({type: FETCH_ERROR, payload: data.message});
			}
		}).catch(function (error) {
			dispatch({type: FETCH_ERROR, payload: error});
		});
	};
};

export const getCurriculum = (datos) =>  {
	return (dispatch) => {
		dispatch({type: FETCH_START_CURRICULUM});
		axios.get("curriculums?doctor="+datos
		).then(({data}) => {
			if (data.result) {
				dispatch({type: FETCH_SUCCESS_CURRICULUM});
				dispatch({type: SET_CURRICULUM, payload: data.curriculum});
			} else {
				dispatch({type: FETCH_ERROR_CURRICULUM, payload: data.message});
			}
		}).catch(function (error) {
			dispatch({type: FETCH_ERROR_CURRICULUM, payload: error});
		});
	};
};

export const setCurriculumDoctor = (datos, action, doctor="null") =>  {
	return (dispatch) => {
		dispatch({type: FETCH_START});
		axios.put("curriculums?doctor="+doctor, datos
		).then(({data}) => {
			if (data.result) {
				dispatch({type: FETCH_SUCCESS});
				dispatch({type: FETCH_ACTION, payload: action});
			} else {
				dispatch({type: FETCH_ERROR, payload: data.message});
			}
		}).catch(function (error) {
			dispatch({type: FETCH_ERROR, payload: error});
		});
	};
};