import {SET_CURRICULUM} from "constants/ActionTypes";
import {FETCH_START_CURRICULUM, FETCH_SUCCESS_CURRICULUM, FETCH_ACTION_CURRICULUM, FETCH_ERROR_CURRICULUM} from "../../constants/ActionTypes";

const INIT_STATE = {
	error_curriculum: "",
	loading_curriculum: false,
	message_curriculum: "",
	response_curriculum: null,
	action_curriculum: "",
	curriculum: null
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
	case FETCH_START_CURRICULUM: {
		return {...state, error_curriculum: "", message_curriculum: "", loading_curriculum: true, response_curriculum: null, action_curriculum: ""};
	}
	case FETCH_SUCCESS_CURRICULUM: {
		return {...state, error_curriculum: "", message_curriculum: "", loading_curriculum: false, response_curriculum: null, action_curriculum: ""};
	}
	case FETCH_ACTION_CURRICULUM: {
		return {...state, error_curriculum: "", message_curriculum: "", loading_curriculum: false, response_curriculum: true, action_curriculum: action.payload};
	}
	case FETCH_ERROR_CURRICULUM: {
		return {...state, loading_curriculum: false, error_curriculum: action.payload, message_curriculum: "", response_curriculum: false, action_curriculum: ""};
	}
	case SET_CURRICULUM: {
		return {...state, curriculum: action.payload};
	}
	default:
		return state;
	}
};
