import {FETCH_START_MODAL, FETCH_SUCCESS_MODAL, RESET_ACTION_MODAL, FETCH_ERROR_MODAL, FETCH_ACTION_OK} from "../../constants/ActionTypes";

const INIT_STATE = {
	error: "",
	loading: false,
	message: "",
	navCollapsed: true,
	pathname: "/",
	response: null,
	action: ""
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
	case "@@router/LOCATION_CHANGE": {
		return {
			...state,
			pathname: action.payload.location.pathname,
			navCollapsed: false
		};
	}
	case FETCH_START_MODAL: {
		return {...state, error: "", message: "", loading: true, response: null, action: ""};
	}
	case FETCH_SUCCESS_MODAL: {
		return {...state, error: "", message: "", loading: false, response: null, action: ""};
	}
	case FETCH_ACTION_OK: {
		return {...state, error: "", message: "", loading: false, response: true, action: action.payload};
	}
	case FETCH_ERROR_MODAL: {
		return {...state, loading: false, error: action.payload, message: "", response: false, action: ""};
	}
	case RESET_ACTION_MODAL: {
		return {...state, loading: false, error: "", message: "", response: null, action: ""};
	}
	default:
		return state;
	}
};
