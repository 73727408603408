import {FETCH_ERROR, FETCH_START, FETCH_SUCCESS, HIDE_MESSAGE, SHOW_MESSAGE, FETCH_OK, UPDATE_PERMISSIONS, RESET_ACTION_COMMON, RESET_ACTION_MODAL} from "../../constants/ActionTypes";

export const fetchStart = () => {
	return {
		type: FETCH_START
	};
};

export const fetchSuccess = () => {
	return {
		type: FETCH_SUCCESS
	};
};

export const fetchError = (error) => {
	return {
		type: FETCH_ERROR,
		payload: error
	};
};

export const showMessage = (message) => {
	return {
		type: SHOW_MESSAGE,
		payload: message
	};
};

export const hideMessage = () => {
	return {
		type: HIDE_MESSAGE
	};
};

export const fetchOk = () => {
	return {
		type: FETCH_OK
	};
};

export const updatePermissions = () => {
	return {
		type: UPDATE_PERMISSIONS
	};
};

export const resetActionCommon = () => {
	return {
		type: RESET_ACTION_COMMON
	};
};

export const resetActionModal = () => {
	return {
		type: RESET_ACTION_MODAL
	};
};







