import {INIT_URL, BUSINESS_TYPES_SELECT_SET, BUSINESS_TYPES_ACTION } from "../../constants/ActionTypes";

const INIT_STATE = {
	initURL: "",
	business_types: null,
	action: null
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {

	case INIT_URL: {
		return {...state, initURL: action.payload};
	}

	case BUSINESS_TYPES_SELECT_SET: {
		return {
			...state,
			business_types: action.payload,
		};
	}

	case BUSINESS_TYPES_ACTION: {
		return {
			...state,
			action: action.payload,
		};
	}

	default:
		return state;
	}
};
