import {
	FETCH_ERROR,
	FETCH_ERROR_MODAL,
	FETCH_START,
	FETCH_START_MODAL,
	FETCH_SUCCESS,
	FETCH_SUCCESS_MODAL,
	INIT_URL,
	USERS_SET,
	USER_SET,
	USER_ACTION,
	FETCH_ACTION_OK,
	FETCH_OK_MODAL,
	FETCH_ACTION,
	USERS_DATA
} from "../../constants/ActionTypes";
import axios from "util/Api";
  
export const setInitUrl = (url) => {
	return {
		type: INIT_URL,
		payload: url
	};
};
  
export const getUsers = (page, take, sort, busqueda) => {
	return (dispatch) => {
		dispatch({type: FETCH_START});
		axios.get("users?page="+page+"&take="+take+"&sort="+sort+"&field="+busqueda).then(({data}) => {
			if (data) {
				dispatch({type: FETCH_SUCCESS});
				dispatch({type: USERS_SET, payload: data.users});
				//dispatch({type: FETCH_OK});
			} else {
				dispatch({type: FETCH_ERROR, payload: data.error.message});
			}
		}).catch(function (error) {
			dispatch({type: FETCH_ERROR, payload: error});
		});
	};
};

export const getUser = (id, action) => {
	return (dispatch) => {
		dispatch({type: FETCH_START_MODAL});
		axios.get("users/"+id).then(({data}) => {
			if (data) {
				dispatch({type: FETCH_SUCCESS_MODAL});
				dispatch({type: USER_SET, payload: data.user});
				dispatch({type: FETCH_ACTION_OK, payload: action});
			} else {
				dispatch({type: FETCH_ERROR_MODAL, payload: data.error.message});
			}
		}).catch(function (error) {
			dispatch({type: FETCH_ERROR_MODAL, payload: error});
		});
	};
};

export const getAction = (action) => {
	return (dispatch) => {
		dispatch({type: USER_ACTION, payload: action});
	};
};

export const resetActions = () => {
	return (dispatch) => {
		dispatch({type: USER_ACTION, payload: [[],[]]});
	};
};

export const setAction = (action) => {
	return (dispatch) => {
		dispatch({type: USER_ACTION, payload: action});
	};
};

export const addUser = (datos, action) =>  {
	return (dispatch) => {
		dispatch({type: FETCH_START_MODAL});
		axios.post("users", datos
		).then(({data}) => {
			if (data.result) {
				dispatch({type: FETCH_SUCCESS_MODAL});
				dispatch({type: FETCH_OK_MODAL});
				dispatch({type: FETCH_ACTION_OK, payload: action});
			} else {
				dispatch({type: FETCH_ERROR_MODAL, payload: data.message});
			}
		}).catch(function (error) {
			dispatch({type: FETCH_ERROR_MODAL, payload: error});
		});
	};
};

export const updateUser = (data, action) =>  {
	return (dispatch) => {
		dispatch({type: FETCH_START_MODAL});
		axios.put("users", data
		).then(({data}) => {
			if (data.result) {
				dispatch({type: FETCH_SUCCESS_MODAL});
				dispatch({type: FETCH_OK_MODAL});
				dispatch({type: FETCH_ACTION_OK, payload: action});
			} else {
				dispatch({type: FETCH_ERROR_MODAL, payload: data.message});
			}
		}).catch(function (error) {
			dispatch({type: FETCH_ERROR_MODAL, payload: error});
		});
	};
};

/* export const updateAsistente = (data, action) =>  {
	return (dispatch) => {
		dispatch({type: FETCH_START_MODAL});
		axios.put("asistentes", data
		).then(({data}) => {
			if (data.result) {
				dispatch({type: FETCH_SUCCESS_MODAL});
				dispatch({type: FETCH_OK_MODAL});
				dispatch({type: FETCH_ACTION_OK, payload: action});
			} else {
				dispatch({type: FETCH_ERROR_MODAL, payload: data.message});
			}
		}).catch(function (error) {
			dispatch({type: FETCH_ERROR_MODAL, payload: error});
		});
	};
}; */

export const removeUser = (data, action) =>  {
	return (dispatch) => {
		dispatch({type: FETCH_START_MODAL});
		axios.delete("users/"+data).then(({data}) => {
			if (data) {
				dispatch({type: FETCH_SUCCESS_MODAL});
				dispatch({type: FETCH_OK_MODAL});
				dispatch({type: FETCH_ACTION_OK, payload: action});
			} else {
				dispatch({type: FETCH_ERROR_MODAL, payload: data.error.message});
			}
		}).catch(function (error) {
			dispatch({type: FETCH_ERROR_MODAL, payload: error});
		});
	};
};

export const userSignUpParentPacient = (data, action) =>  {
	return (dispatch) => {
		dispatch({type: FETCH_START});
		axios.post("pacients", data
		).then(({data}) => {
			if (data.result) {
				dispatch({type: FETCH_SUCCESS});
				dispatch({type: FETCH_ACTION, payload: action});
			} else {
				dispatch({type: FETCH_ERROR, payload: data.message});
			}
		}).catch(function (error) {
			dispatch({type: FETCH_ERROR, payload: error});
		});
	};
};

export const userSignUpRefer= (data, action) =>  {
	return (dispatch) => {
		dispatch({type: FETCH_START});
		axios.post("refers", data
		).then(({data}) => {
			if (data.result) {
				dispatch({type: FETCH_SUCCESS});
				dispatch({type: FETCH_ACTION, payload: action});
			} else {
				dispatch({type: FETCH_ERROR, payload: data.message});
			}
		}).catch(function (error) {
			dispatch({type: FETCH_ERROR, payload: error});
		});
	};
};

export const userSignUpDoctor = (data, action) =>  {
	return (dispatch) => {
		dispatch({type: FETCH_START});
		axios.post("doctors", data
		).then(({data}) => {
			if (data.result) {
				dispatch({type: FETCH_SUCCESS});
				dispatch({type: FETCH_ACTION, payload: action});
			} else {
				dispatch({type: FETCH_ERROR, payload: data.message});
				dispatch({type: FETCH_ERROR, payload: ""});
			}
		}).catch(function (error) {
			dispatch({type: FETCH_ERROR, payload: error});
		});
	};
};

export const userSignUpBusiness = (data, action) =>  {
	return (dispatch) => {
		dispatch({type: FETCH_START});
		axios.post("businesses", data
		).then(({data}) => {
			if (data.result) {
				dispatch({type: FETCH_SUCCESS});
				dispatch({type: FETCH_ACTION, payload: action});
			} else {
				dispatch({type: FETCH_ERROR, payload: data.message});
				dispatch({type: FETCH_ERROR, payload: ""});
			}
		}).catch(function (error) {
			dispatch({type: FETCH_ERROR, payload: error});
		});
	};
};

export const getUsersPending = () => {
	return (dispatch) => {
		dispatch({type: FETCH_START});
		axios.get("users_pendings").then(({data}) => {
			if (data) {
				dispatch({type: FETCH_SUCCESS});
				dispatch({type: USERS_DATA, payload: data});
				//dispatch({type: FETCH_OK});
			} else {
				dispatch({type: FETCH_ERROR, payload: data.error.message});
			}
		}).catch(function (error) {
			dispatch({type: FETCH_ERROR, payload: error});
		});
	};
};

export const getUsersAsistentes = (page, take, sort, busqueda) => {
	return (dispatch) => {
		dispatch({type: FETCH_START});
		axios.get("usersAsistentes?page="+page+"&take="+take+"&sort="+sort+"&field="+busqueda).then(({data}) => {
			if (data) {
				dispatch({type: FETCH_SUCCESS});
				dispatch({type: USERS_SET, payload: data.users});
				//dispatch({type: FETCH_OK});
			} else {
				dispatch({type: FETCH_ERROR, payload: data.error.message});
			}
		}).catch(function (error) {
			dispatch({type: FETCH_ERROR, payload: error});
		});
	};
};

export const addAsistente = (data, action) =>  {
	return (dispatch) => {
		dispatch({type: FETCH_START_MODAL});
		axios.post("usersAsistente", data
		).then(({data}) => {
			if (data.result) {
				dispatch({type: FETCH_SUCCESS_MODAL});
				dispatch({type: FETCH_OK_MODAL});
				dispatch({type: FETCH_ACTION_OK, payload: action});
			} else {
				dispatch({type: FETCH_ERROR_MODAL, payload: data.message});
			}
		}).catch(function (error) {
			dispatch({type: FETCH_ERROR_MODAL, payload: error});
		});
	};
};

export const getUsersAdmins = (page, take, sort, busqueda) => {
	return (dispatch) => {
		dispatch({type: FETCH_START});
		axios.get("usersAdmins?page="+page+"&take="+take+"&sort="+sort+"&field="+busqueda).then(({data}) => {
			if (data) {
				dispatch({type: FETCH_SUCCESS});
				dispatch({type: USERS_SET, payload: data.users});
				//dispatch({type: FETCH_OK});
			} else {
				dispatch({type: FETCH_ERROR, payload: data.error.message});
			}
		}).catch(function (error) {
			dispatch({type: FETCH_ERROR, payload: error});
		});
	};
};

export const addAdmins = (data, action) =>  {
	return (dispatch) => {
		dispatch({type: FETCH_START_MODAL});
		axios.post("usersAdmin", data
		).then(({data}) => {
			if (data.result) {
				dispatch({type: FETCH_SUCCESS_MODAL});
				dispatch({type: FETCH_OK_MODAL});
				dispatch({type: FETCH_ACTION_OK, payload: action});
			} else {
				dispatch({type: FETCH_ERROR_MODAL, payload: data.message});
			}
		}).catch(function (error) {
			dispatch({type: FETCH_ERROR_MODAL, payload: error});
		});
	};
};

export const updateAsistente = (data, action) =>  {
	return (dispatch) => {
		dispatch({type: FETCH_START_MODAL});
		axios.put("usersAsistente", data
		).then(({data}) => {
			if (data.result) {
				dispatch({type: FETCH_SUCCESS_MODAL});
				dispatch({type: FETCH_OK_MODAL});
				dispatch({type: FETCH_ACTION_OK, payload: action});
			} else {
				dispatch({type: FETCH_ERROR_MODAL, payload: data.message});
			}
		}).catch(function (error) {
			dispatch({type: FETCH_ERROR_MODAL, payload: error});
		});
	};
};